import React from "react";
import CreateAplicationFormController, {
  Props,
} from "./CreateApplicationFormController.web";

import {
  Box,
  Typography,
  Grid,
  MenuItem,
  IconButton,
  Button,
} from "@material-ui/core";
import CustomTextInput from "../../../components/src/util/CustomTextInput.web";
import CustomTextArea from "../../../components/src/util/CustomTextArea.web";
import CustomLayout from "../../../components/src/util/CustomLayout.web";
import CustomSelect from "../../../components/src/util/CustomSelect.web";
import TagItem from "../../../components/src/util/TagItem.web";
import { IDepartmentType, ITagType } from "../../Settings5/src/settings.types";
import SelectAttatchment from "../../../components/src/util/SelectAttatchment.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import AttatchmentItem from "../../../components/src/util/AttatchmentItem.web";

export default class EditProject extends CreateAplicationFormController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      submittedDate,
      businessUnit,
      ideaOwnerName,
      description,
      title,
      errors,
      email,
      editProjectTagList,
      attatchment,
      businessUnitList,
      tagList,
      addMoreTag,
      createLoading,
      isAdmin,
      editProjectAttachments,
    } = this.state;
    const middleGrid = 8;
    return (
      <CustomLayout
        isAdmin={isAdmin}
        backButton={true}
        goBack={this.goBack}
        title="Edit Project"
      >
        <Box sx={{ marginTop: "130px" }} />
        <Grid
          style={isAdmin?{
            width: "100%",
            padding: "0px 30px",
            boxSizing: "border-box",

          }:{width: "80%",margin:"0 auto"}}
          container
          spacing={4}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={middleGrid}
            lg={middleGrid}
            xl={middleGrid}
            style={{
              height: "calc(100vh - 114px)",
              // overflow: "scroll",
            }}
            // className="custom_scrollbar"
          >
            <Box sx={{ width: "100%" }}>
              <CustomTextInput
                data-testid="ed_ideaTitleInput"
                placeholder="Idea Title"
                label="Idea Title"
                value={title}
                onChange={this.handleTitleChange}
                error={errors?.title}
              />
              <br />
              <CustomTextInput
                data-testid="ed_ideaOwnerName"
                label="Idea Owner Name"
                value={ideaOwnerName}
                placeholder="Idea Owner Name"
                onChange={this.handleIdeaOwnerNameChange}
                error={errors?.ideaOwnerName}
              />
              <br />
              <CustomTextInput
                data-testid="ed_emailAddressInput"
                label="Email Address"
                placeholder="Email Address"
                value={email}
                onChange={this.handleEmailChange}
                error={errors?.email}
              />
              <br />
              <CustomSelect
                data-testid="ed_businessUnitSelect"
                placeholder="Business Unit"
                label={`Business Unit`}
                onChange={this.handleBusinessUnitChange}
                data={businessUnitList}
                value={businessUnit}
                error={errors?.businessUnit}
                renderItem={(item: IDepartmentType) => (
                  <MenuItem value={item.id}>{item.attributes.name}</MenuItem>
                )}
              />
              <br />
              <CustomTextInput
                data-testid="ed_dateSubmittedInput"
                placeholder="06/03/2002"
                label="Date Submitted"
                type="text"
                readOnly={true}
                value={submittedDate}
                onChange={this.handleSubmittedDateChange}
                error={errors?.submittedDate}
              />
              <br />

              <CustomTextArea
                data-testid="ed_ideaDescriptionInput"
                placeholder="Please tell us about your idea.."
                label="Idea Description"
                rows={4}
                onChange={this.handleDescriptionChange}
                value={description}
                error={errors?.description}
              />
              <br />
              <div style={webStyle.devider_style} />
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  borderRadius: "8px",
                }}
              >
                <Button
                  data-testid="goBack"
                  onClick={this.goBack}
                  variant="contained"
                  style={{
                    background: "white",
                    textTransform: "capitalize",
                    width: "120px",
                    boxShadow: "none",
                    borderRadius: "8px",
                  }}
                >
                  Back
                </Button>
                <Button
                  data-testid="updateButton"
                  onClick={this.validateInput}
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "capitalize",
                    width: "120px",
                    boxShadow: "none",
                    borderRadius: "8px",
                  }}
                >
                  {createLoading ? (
                    <CircularProgress style={{ color: "white" }} size={24} />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <br />
            <Typography
              style={{
                color: "#666666",
                fontWeight: 600,
                fontSize: "16px",
              }}
            >
              File Attachments
            </Typography>
            <Box
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              {editProjectAttachments?.map((item, index) => (
                <AttatchmentItem
                  key={item.document_id}
                  id={item.document_id}
                  clickable={true}
                  deletable={true}
                  fileName={item?.filename}
                  fileSize={(Math.round(item.filesize * 100) / 100).toFixed(2)}
                  fileType={item?.extension}
                  url={item?.url}
                  deleteHandler={this.handleDeleteAttachment}
                />
              ))}
            </Box>

            <SelectAttatchment
              attatchment={attatchment}
              changeHandler={this.handleAttatchmentChange}
              removeHandler={this.handleRemoveAttatchment}
              error={errors?.attatchment}
              label={false}
            />
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
                marginTop: "15px",
                marginBottom: "15px",
              }}
            >
              <Typography component="h1" variant="h3" style={webStyle.title}>
                Tags
              </Typography>
              <IconButton
                data-testid="addMoreTag"
                onClick={this.handleAddMoreTag}
              >
                <AddIcon />
              </IconButton>
            </div>
            {errors?.tags && (
              <Typography
                style={{
                  color: "red",
                  fontSize: "14px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                component="p"
                variant="h6"
              >
                {errors?.tags}
              </Typography>
            )}

            {addMoreTag && (
              <CustomSelect
                data-testid="tagsInput"
                label="Tags"
                placeholder="Tags"
                value={this.state.savedTag}
                editTag={true}
                data={tagList}
                renderItem={(item: ITagType) => (
                  <MenuItem value={item.id}>{item?.attributes?.name}</MenuItem>
                )}
                onChange={this.addMoreTag}
              />
            )}

            <Box
              style={{
                marginTop: "15px",
                background: "white",
                borderRadius: "15px",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
            >
              {editProjectTagList.map((item, index: number) => (
                <TagItem
                  tag={item}
                  key={index}
                  checked={true}
                  handleRemoveTag={this.handleRemoveTag}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </CustomLayout>
    );
  }
}

const webStyle = {
  title: {
    color: "black",
    fontSize: "28px",
    fontWeight: 600,
  },
  devider_style: {
    height: "2px",
    width: "100%",
    marginBottom: "15px",
    marginTop: "15px",
    background: "#E4E4E4",
  },
};
