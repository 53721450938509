import React, { CSSProperties } from "react";
import ProjectDetailsController, {
  Props,
} from "./ProjectDetailsController.web";

import {
  Box,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
import CustomTextInput from "../../../components/src/util/CustomTextInput.web";
import ProjectItem from "../../../components/src/util/ProjectItem.web";
import CustomLayout from "../../../components/src/util/CustomLayout.web";
import AttatchmentItem from "../../../components/src/util/AttatchmentItem.web";

export default class ProjectDetails extends ProjectDetailsController {
  constructor(props: Props) {
    super(props);
    this.ref = React.createRef();
  }

  render() {
    const {
      submittedDate,
      businessUnit,
      ideaOwnerName,
      description,
      title,
      tags,
      similarProjects,
      documents,
      isAdmin,
      canEdit
    } = this.state;
    const middleGrid = isAdmin ? 8 : 6;
    const rightGrid = isAdmin ? 4 : 3;

    return (
      <CustomLayout
        isAdmin={isAdmin}
        backButton={false}
        title={title ? title : "Project Title"}
        headerBackButton={isAdmin?false:true}
      >
        <Box sx={{ marginTop: "130px" }} />
        <Grid
          style={{
            width: "100%",
            padding: isAdmin ? "0px 30px" : "0px",
            boxSizing: "border-box",
          }}
          container
          spacing={4}
        >
          {isAdmin || (
            <Grid
              data-testid="user-leftsidebar"
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
            >
            
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={12}
            md={middleGrid}
            lg={middleGrid}
            xl={middleGrid}
            ref={this.ref}
          >
            <Box sx={{ width: "100%" }}>
              <CustomTextInput
                label="Idea Owner Name"
                placeholder="Idea Owner Name"
                value={ideaOwnerName}
                readOnly={true}
              />
              <br />
              <CustomTextInput
                label="Business Unit"
                placeholder="Business Unit"
                value={businessUnit}
                readOnly={true}
              />
              <br />
              <CustomTextInput
                label="Date Submitted"
                placeholder="Date Submitted"
                value={submittedDate}
                readOnly={true}
              />
              <br />
              <Typography
                component="h1"
                variant="h3"
                style={webStyle.headingStyle}
              >
                Idea Description
              </Typography>
              <Box
                style={{
                  background: "white",
                  borderRadius: "8px",
                  padding: "15px 20px",
                  minHeight: "150px",
                  marginTop: "7px",
                }}
              >
                <Typography
                  component="p"
                  style={{
                    color: "black",
                    fontWeight: 500,
                    fontSize: "17px",
                  }}
                >
                  {description}
                </Typography>
              </Box>
              <br />
              <div style={webStyle.devider} />
              <Typography
                component="h1"
                variant="h3"
                style={webStyle.headerTitleStyle}
              >
                Similar Projects
              </Typography>
              <Grid container spacing={4}>
                {similarProjects.map((item, index) => (
                  <ProjectItem
                    key={index}
                    projectData={item}
                    navigation={this.props.navigation}
                    getProjectDetail={this.getProjectDetail}
                  />
                ))}
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={rightGrid}
            lg={rightGrid}
            xl={rightGrid}
          >
            {canEdit && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginBottom: "15px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{
                      textTransform: "capitalize",
                      boxShadow: "none",
                      borderRadius: "8px",
                      width: 150,
                    }}
                    onClick={this.navigateToEditProject}
                  >
                    Edit
                  </Button>
                </div>
              </>
            ) }
            <div style={{ paddingBottom: 20 }}>
                  <Typography
                    component="h1"
                    variant="h3"
                    style={webStyle.attachmentHeading}
                  >
                    {documents.length !== 0
                      ? "File Attachments"
                      : "No attachment found"}
                  </Typography>

                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    {documents.map((item, index) => (
                      <a
                        key={index}
                        href={item?.url}
                        download={item?.filename}
                        target="blank"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <AttatchmentItem
                          id={item.document_id}
                          clickable={false}
                          deletable={false}
                          fileName={item?.filename}
                          fileSize={(
                            Math.round(item.filesize * 100) / 100
                          ).toFixed(2)}
                          fileType={item?.extension}
                          url={item?.url}
                        />
                      </a>
                    ))}
                  </Box>
                </div>
              <div>
                <Typography
                  component="h1"
                  variant="h3"
                  style={webStyle.headerTitleStyle}
                >
                  Tags
                </Typography>
                <Box
                  style={{
                    marginTop: "15px",
                    background: "white",
                    borderRadius: "15px",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    marginRight: "20px",
                  }}
                >
                  {tags.map((item: any, index: any) => (
                    <Box
                      key={index}
                      style={{
                        background: "#F5F6F7",
                        borderRadius: "8px",
                        padding: "8px 12px",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          width: "14px",
                          height: "14px",
                          borderRadius: "50%",
                          background: item?.color,
                        }}
                      />
                      <Typography
                        component="h2"
                        variant="h5"
                        style={{ fontSize: "14px" }}
                      >
                        {item?.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </div>
          </Grid>
        </Grid>
      </CustomLayout>
    );
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    bgcolor: "#F2F2F2",
    height: "100vh",
    overflow: "hidden",
    paddingBottom: "50px",
  },
  logo: {
    width: "162px",
    height: "40px",
    objectFit: "cover",
  },
  inputLabel: {
    color: "#666666",
    fontSize: "16px",
    fontWeight: 500,
  },

  devider: {
    height: "2px",
    width: "100%",
    marginTop: "15px",
    marginBottom: "15px",
    background: "#E4E4E4",
  },
  headerTitleStyle: {
    fontSize: "28px",
    fontWeight: 600,
    color: "black",
    marginBottom: "15px",
  },
  attachmentHeading: {
    fontSize: "16px",
    color: "#666666",
    paddingBottom: "15px",
  },
  pdfImage: {
    height: "148px",
    width: "121px",
    top: "52px",
    borderRadius: 0,
    position: "relative",
    marginTop: "auto",
  },
  pdfName: {
    fontSize: 18,
    color: "#171717",
  },
  pdfSize: {
    fontSize: 18,
    color: "#171717",
    float: "right",
  },
  checkBox: {
    padding: "0",
    color: "#E46E2A !important",
  },
  checkBoxLabel: {
    marginLeft: "0px",
    display: "flex",
    justifyContent: "space-between",
    background: "#F5F6F7",
    padding: "8px 12px",
    marginTop: "12px",
    borderRadius: "8px",
  },
  checkBoxColors: {
    backgroundColor: "#000000",
    borderRadius: "100%",
    width: "12px",
    height: "12px",
    display: "inline-block",
    marginRight: "8px",
  },
  listScrollText: {
    fontSize: "14px",
  },
  listCheckBox: {
    height: "auto",
    overflowY: "auto",
    overflowX: "hidden",
    paddingRight: "12px",
    width: "100%",
    scrollbarWidth: "none",
  },
  headingStyle: {
    color: "#666666",
    fontSize: "16px",
    fontWeight: 500,
  },
};
