import React from "react";
import CustomFormController, {
  Props,
  configJSON,
} from "./CreateApplicationFormController.web";
import { Box, Typography, Button } from "@material-ui/core";
import AppHeader from "../../../components/src/util/AppHeader.web";
import { formSubmitImg } from "./assets";
export default class CustomFormSubmit extends CustomFormController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Box sx={webStyle.container}>
        <AppHeader
          isAdmin={this.state.isAdmin}
          logo={false}
          optionButton={true}
          title={configJSON.headerApplicantFormTitle}
          titleStyle={{
            marginLeft: "20px",
          }}
        />
        <Box sx={{ marginTop: "130px" }} />

        <Box style={{ width: "100%", padding: "50px 100px" }}>
          <Box
            style={{
              width: "100%",
              background: "white",
              borderRadius: "24px",
              display: "flex",
              alignItems: "center",
              paddingTop: "84px",
              paddingBottom: "30px",
              gap: "10px",
              flexDirection: "column",
            }}
          >
            <Typography
              component="h1"
              variant="h4"
              style={webStyle.submitMessage}
            >
              Thank you for submitting your idea,
              <br />
              The Nest team will be in touch shortly!
            </Typography>
            <img
              src={formSubmitImg}
              alt="Form Submit Success"
              style={{
                width: "450px",
                height: "450px",
                objectFit: "cover",
              }}
            />
            <Button
              onClick={this.navigateToHomePage}
              variant="contained"
              color="primary"
              style={{
                textTransform: "capitalize",
                width: "120px",
                boxShadow: "none",
                borderRadius: "8px",
                marginTop: "50px",
              }}
            >
              Ok
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
}

const webStyle = {
  container: {
    bgcolor: "#F2F2F2",
    height: "100vh",
    overflow: "auto",
    paddingBottom: "50px",
  },
  submitMessage: {
    fontSize: "36px",
    fontWeight: 600,
    color: "black",
    marginBottom: "15px",
  },
};
