export const imgAdd = require("../assets/ic_add.png");
export const imgCancel = require("../assets/ic_cancel.png");
export const imgGstin = require("../assets/ic_gstin.png");
export const imgMapIcon = require("../assets/ic_map_icon.png");
export const imgSuccessModal = require("../assets/success_modal_image.png");
export const backIcon = require("../assets/backIcon.png");
export const logoImg = require("../assets/logo.png");
export const formSubmitImg = require("../assets/form_submit.png");
export const newProjectActiveImg = require("../assets/new_project_active.svg");
export const newProjectImg = require("../assets/new_project.svg");
export const browseProjectActiveImg = require("../assets/browse_project_active.svg");
export const browseProjectImg = require("../assets/browse_project.svg");
export const settingsImg = require("../assets/settings.svg");
export const settingsActiveImg = require("../assets/settings_active.svg");
export const imgPdf = require("../assets/pdf.png");
export const imgDoc = require("../assets/docImg.png");
export const imgFile = require("../assets/file.png");
export const imgf1a3176465e6ccd5c130db71bca6c0ad50d0f87c = require("../../global_assets/f1a3176465e6ccd5c130db71bca6c0ad50d0f87c.png");
export const imgic_password_invisible = require("../../global_assets/ic_password_invisible.png");
export const imgic_password_visible = require("../../global_assets/ic_password_visible.png");
export const img0070bcbdbcddb34a45cc3c2301a0be328d5462be = require("../../global_assets/0070bcbdbcddb34a45cc3c2301a0be328d5462be.png");
export const imge17b4449ca0a29a8d1ea6855b216a25fbe307a79 = require("../../global_assets/e17b4449ca0a29a8d1ea6855b216a25fbe307a79.png");
export const img29ec1ea2dfc7ce4d588cbbe7ff0923cc335e79c4 = require("../../global_assets/29ec1ea2dfc7ce4d588cbbe7ff0923cc335e79c4.png");
export const img5e64bac6bdcfdcbb946a7a74ec5ae4dea1218f4b = require("../../global_assets/5e64bac6bdcfdcbb946a7a74ec5ae4dea1218f4b.png");
export const img355edd9c6f14ad8dd3e7152793882bb20812856f = require("../../global_assets/355edd9c6f14ad8dd3e7152793882bb20812856f.png");
export const imgcd859914b30d52571effc5de1a3ae8fe5a2cf44d = require("../../global_assets/cd859914b30d52571effc5de1a3ae8fe5a2cf44d.png");
export const imgc7197d346ed2f750274c372970d2b876276e7903 = require("../../global_assets/c7197d346ed2f750274c372970d2b876276e7903.png");
export const imgcdab46b33d22c81a93e24ebbeb816f54e187a11e = require("../../global_assets/cdab46b33d22c81a93e24ebbeb816f54e187a11e.png");
export const img69c6e2c38907d083d70aeb4f423f056d77504208 = require("../../global_assets/69c6e2c38907d083d70aeb4f423f056d77504208.png");
export const img8bc28ecf4624d9ee7a2d6814a58b4a234739b9cb = require("../../global_assets/8bc28ecf4624d9ee7a2d6814a58b4a234739b9cb.png");
export const img04065a8afa250eb252fefa5cf20009b33fdf8998 = require("../../global_assets/04065a8afa250eb252fefa5cf20009b33fdf8998.png");
export const imgd0412bb2d029f1890a0808d9b442c5a2f4193882 = require("../../global_assets/d0412bb2d029f1890a0808d9b442c5a2f4193882.png");
export const imgf55108451faedc6f5cb2a88baf57d5c17b756298 = require("../../global_assets/f55108451faedc6f5cb2a88baf57d5c17b756298.png");
export const img91852a0c0feb5aa2eb40a6ea6789bbcc8bc3c259 = require("../../global_assets/91852a0c0feb5aa2eb40a6ea6789bbcc8bc3c259.png");
export const imga5c98aef47f94e8d829c3a5e3752e4f83bd1dddc = require("../../global_assets/a5c98aef47f94e8d829c3a5e3752e4f83bd1dddc.png");
export const img00d74f8982d5975aebbc834243add8a2a2f96771 = require("../../global_assets/00d74f8982d5975aebbc834243add8a2a2f96771.png");
export const imgf7cdc55f944571bd108b7836831831427048f5e5 = require("../../global_assets/f7cdc55f944571bd108b7836831831427048f5e5.png");
export const imgbf2e961518ff4e2c079c7a6c76096c1787e1ea70 = require("../../global_assets/bf2e961518ff4e2c079c7a6c76096c1787e1ea70.png");
export const imgc76fbe0db041729a3a66aebedff1a860f826c1d5 = require("../../global_assets/c76fbe0db041729a3a66aebedff1a860f826c1d5.png");
export const imgcfb741185ccfd543aa8b2449a1d93abfe36263d1 = require("../../global_assets/cfb741185ccfd543aa8b2449a1d93abfe36263d1.png");
export const img11ac62da43b0f06a2bedbf638c70f005b82fd19b = require("../../global_assets/11ac62da43b0f06a2bedbf638c70f005b82fd19b.png");
export const img7808c4a1c281f26ea60e7dcfaee09dd117a63469 = require("../../global_assets/7808c4a1c281f26ea60e7dcfaee09dd117a63469.png");
export const img028c7351fd7d43469f4d7cb9b48164b5305f5ff0 = require("../../global_assets/028c7351fd7d43469f4d7cb9b48164b5305f5ff0.png");
export const img1710a12ef4c67d76e4cd43223da547bad9709c78 = require("../../global_assets/1710a12ef4c67d76e4cd43223da547bad9709c78.png");
export const img9d78b3596f3146104f1b99305e2db67d7b1f6909 = require("../../global_assets/9d78b3596f3146104f1b99305e2db67d7b1f6909.png");
export const img6a267dd194eeb69e5d8caebdbfd2d38780b61c11 = require("../../global_assets/6a267dd194eeb69e5d8caebdbfd2d38780b61c11.png");
export const img49114249ad20db7012166c58b74711726bc5e3a5 = require("../../global_assets/49114249ad20db7012166c58b74711726bc5e3a5.png");
export const img3f9f166768e22e7d1ddb79395993aa306b1e4707 = require("../../global_assets/3f9f166768e22e7d1ddb79395993aa306b1e4707.png");
export const imgab72963a95161485a7b20d881a26720a84544e92 = require("../../global_assets/ab72963a95161485a7b20d881a26720a84544e92.png");
export const img765c4718d63d6f9988e5ba1f16d92aa0427b89dd = require("../assets/765c4718d63d6f9988e5ba1f16d92aa0427b89dd.png");
export const img0c4fba9121c676e0a99aef9c976609b71c42ccfc = require("../assets/0c4fba9121c676e0a99aef9c976609b71c42ccfc.png");
export const img853f7311ea14b3e63453ee491ddd946bf12cbcd5 = require("../assets/853f7311ea14b3e63453ee491ddd946bf12cbcd5.png");
export const img6d9db0a2d79dea024131c80496b1532e5ef25f1d = require("../assets/6d9db0a2d79dea024131c80496b1532e5ef25f1d.png");
export const imgc7fa9f826eea85158e188f6e8d6b1fd0f268f27f = require("../assets/c7fa9f826eea85158e188f6e8d6b1fd0f268f27f.png");
export const img2e3a7e996b4794421cfdc3c0dc030d8722f01c9e = require("../assets/2e3a7e996b4794421cfdc3c0dc030d8722f01c9e.png");
export const imgf3455cf2bdf8ff07928de887c988b047c120c0ab = require("../assets/f3455cf2bdf8ff07928de887c988b047c120c0ab.png");
export const img88f78886f60fe2d5b6db374bdfbde3b83ffc6847 = require("../assets/88f78886f60fe2d5b6db374bdfbde3b83ffc6847.png");
export const img634615d8e51758479483219ce192f0ca27f36d6c = require("../assets/634615d8e51758479483219ce192f0ca27f36d6c.png");
export const imgd45e4e5a816b73a70f55e2c4fa103bb4367e9629 = require("../assets/d45e4e5a816b73a70f55e2c4fa103bb4367e9629.png");
export const imgaa666927a631477cd8e6ee78fb552382e30d0510 = require("../assets/aa666927a631477cd8e6ee78fb552382e30d0510.png");
