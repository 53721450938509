import React, { CSSProperties } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import {
  Box, 
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

export function AppHeader(props:any) {
  return <View style={styles.container}>
      <Box sx={webStyle.proHeading}>
              <Typography variant="h5" style={webStyle.title as CSSProperties}>
                {props.heading}
              </Typography>
            </Box>
    </View>
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: '#f3f3f3',
  },
  text: {
   fontSize: 36,
   fontWeight: '600',
  }
})
const webStyle = {
 
  proHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 32px 18px',
    borderBottom: '1px solid #E4E4E4',
    width: '100%',
    boxSizing: 'border-box',
  },
  helpIcon: {
    color: '#444',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '50%',
    boxShadow: '0px 3px 6px rgba(228, 110, 42, 0.1)',
    width: '40px',
    height: '40px',
    justifyContent: 'center',
  },
  title: {
    fontWeight: '500',
    fontSize: '32px',
    color: '#171717',
  },  
};
