import React from 'react'
import { FlatTagType } from '../../../blocks/Settings5/src/settings.types';
import {Box,Typography,IconButton} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
interface Props{
    tag:FlatTagType;
    checked:boolean;
    handleRemoveTag:(tagId:number)=>void;
}
const TagItem=({tag,checked,handleRemoveTag}:Props)=>{
    return (
      <Box
        style={{
          background: "#F5F6F7",
          borderRadius: "8px",
          padding: "8px 12px",
          display: "flex",
          alignItems: "center",
          justifyContent:"space-between"
        }}
      >
        <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
        <div
          style={{
            minWidth: "14px",
            minHeight: "14px",
            borderRadius: "50%",
            background:tag?.color?tag?.color:"#e46e2a",
          }}
        />
        <Typography component="h2" variant="h5" style={{fontSize:"14px"}}>
         {tag?.name}
        </Typography>
        </div>
        <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
          <IconButton data-testid="removeTag" onClick={()=>handleRemoveTag(tag.id)}>
            <DeleteIcon/>
          </IconButton>
       </div>
      </Box>
    );
}

export default TagItem;