import React, {CSSProperties} from "react";

import {
  Box,
  Typography,
  Button,
  InputAdornment,
  // Customizable Area Start
  TextField, 
  // Customizable Area End 
} from "@material-ui/core";

  // Customizable Area Start
import SideBar from "../../../components/src/SideBar";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LandingPageController, { Props } from "./LandingPageController.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
import SearchIcon from '@material-ui/icons/Search';
import ProjectCard from "../../../components/src/ProjectCard.web";
import PopupModal from "../../../components/src/PopupModal";
import CircularProgress from "@material-ui/core/CircularProgress";

const theme = createTheme({
  palette: {
    primary: {
      main: "#E46E2A",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
  // Customizable Area End
 
export default class ApplicantLandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
 // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  navigateProduct = (id:any)=>{
    this.showProjectDetail(id);
  }
  // Customizable Area End


  render() {
    // Customizable Area Start
      const {tagList,loading,searchResult}=this.state;
      const checkedList=tagList?.filter((tag:any)=>tag?.checked);
      const tagNames=checkedList?.map((tag)=>tag?.attributes?.name)?.join(",")
       let searchContext="";

      if(tagNames){
        searchContext=tagNames
      }else if(searchResult){
        searchContext=searchResult
      }else{
        searchContext="No Tag Selected"
      }

    // Customizable Area End
    return (
       // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.ApplicantMainWrapper}>
          <SideBar
             taglist={this.state.tagList} 
             checkHandler={this.checkHandler}
             clickOnLogo={()=>{this.handleOnRedirectToLandingPage()}}
          />          
          <Box sx={webStyle.ApplicantWrapper}>
            <AppHeader heading={"Browse Project Portfolio"} />
             <Box sx={webStyle.ApplicantContentCentre}>
              <Box sx={webStyle.ApplicantSearchBox}>
                <form autoComplete="off" style={webStyle.ApplicantFormInput}>
                  <TextField id="outlined-basic" placeholder="Search" variant="outlined" style={webStyle.ApplicantInputField}
                    onChange={(event)=>this.handleOnSearch(event)}
                   InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={webStyle.ApplicantInputAdornment}>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                    value={searchResult}
                  />  
                </form>
                <Button variant="contained" style={webStyle.ApplicantBtnOrange as CSSProperties} onClick={()=>this.handleonClickToNewProject()}>+ New Idea</Button>
              </Box>
              <Box style={webStyle.ApplicantCardData as CSSProperties}>
                <Box>
                  <Typography variant="h5" style={webStyle.ApplicantTitlePort as CSSProperties}>
                    Search Results {loading &&  <CircularProgress style={{color:"#E46E2A"}} size={24}/>}
                  </Typography>
                  <Typography style={webStyle.ApplicantSubTitle as CSSProperties}>
                    {this.state.landingPageProjects?.length} results for {searchContext}
                  </Typography>
                </Box>
                <ProjectCard ProjectData={this.state?.landingPageProjects} navigateProduct={(id:any)=>this.navigateProduct(id)}  loadMore={this.getAllProjects} />
              </Box>
            </Box>
            <PopupModal/>
          </Box>
        </Box>
      </ThemeProvider>
  // Customizable Area End
    );
  }
}

  // Customizable Area Start
const webStyle = {
  ApplicantMainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    background: "#fff",
    overflowX: 'hidden',
    height: 'calc(-33px + 100vh)',
  },
  ApplicantWrapper: {
    background: '#F2F2F2',
    width: 'calc(100% - 312px)',
    fontSize: '14px',
    marginLeft:'312px'
  },
  ApplicantContentCentre: {
    padding: '24px 32px',
  },  
  ApplicantSearchBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px',  '& fieldset': {
      boxShadow: '0px 6px 17px rgba(228, 110, 42, 0.05)',
      borderColor: 'transparent !important',
    },
    '&:hover fieldset': {
      borderColor: 'transparent !important',
    },
  }, 
  ApplicantInputField: {
    background: '#fff',
    minWidth: '450px',
    borderRadius: '20px',
  },
  ApplicantFormInput: {
    marginBottom: '0px',
    display: 'flex',
    gap: '10px',
  },
  ApplicantTitlePort: {
    fontSize: '20px',
    fontWeight: '400',
    display:"flex",
    alignItems:"center",
    gap:"10px",
    marginBottom: '15px',
    color: '#171717'
  },
  ApplicantSubTitle: {
    fontSize: '18px',
    fontWeight: '400',
    color: '#66676A',
  },
  ApplicantCardData: {
    overflowY: 'auto',
    height: 'calc(100vh - 237px)',
    overflowX: 'hidden',
  },
  ApplicantCards: {
    marginTop: '20px',
  },
  ApplicantInputAdornment:{
    opacity: 0.5,
  },
  ApplicantBtnOrange: {
    background:'#E46E2A',
    height: '45px',
    color: '#fff',
    textTransform: 'capitalize',
    fontWeight: '500',
    padding:' 10px 25px',
    fontSize: '14px',
    minWidth: '150px',
  },
};
  // Customizable Area End

