import React from "react";

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

interface Props {
  value?: string;
  error?: string;
  onChange?: (e: any) => void;
  label: string;
  placeholder: string;
  type?: string;
  readOnly?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputLabel: {
      color: "#666666",
      fontSize: "16px",
      fontWeight: 500,
    },
    errorText: {
      color: "red",
      fontSize: "14px",
      fontWeight: 500,
      marginTop: "5px",
      marginBottom: "5px",
    },
    inputArea: {
      background: "white",
      border: "none",
      outline: "none",
      borderRadius: "8px",
      padding: "15px 20px",
      color: "black",
      fontWeight: 500,
      marginTop: "0px",
      fontSize: "17px",
      "&::placeholder": {
        color: "#666666",
        opacity: "0.5",
        fontWeight: 400,
      },
    },
  })
);
const CustomTextInput = ({
  value,
  error,
  onChange,
  label,
  placeholder,
  type = "text",
  readOnly = false,
}: Props) => {
  const styles = useStyles();
  return (
    <Box style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
      <Typography className={styles.inputLabel}>{label}</Typography>
      <input
        readOnly={readOnly}
        className={styles.inputArea}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      {error && <Typography className={styles.errorText}>{error}</Typography>}
    </Box>
  );
};

export default CustomTextInput;
