import React from "react";

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Select,
} from "@material-ui/core";

interface Props {
  data?:any[]
  value?: string;
  error?: string;
  onChange?: (e: any) => void;
  label: string;
  placeholder: string;
  type?: string;
  renderItem:(item:any)=>void;
  editTag?:boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputLabel: {
      color: "#666666",
      fontSize: "16px",
      fontWeight: 500,
    },
    errorText: {
      color: "red",
      fontSize: "14px",
      fontWeight: 500,
      marginTop: "5px",
    },
    dropdown__main: {
      width: "100%",
      background: "transparent",
      fontSize: "17px",
      fontWeight: 500,
      "&:hover": {
        borderBottom: "0px",
      },
      "&::before": {
        borderBottom: "0px !important",
      },
      "&::after": {
        borderBottom: "0px !important",
      },
      "&$selected": {
        borderBottom: "0px !important",
      },
    },
  })
);
const CustomSelect = ({
  value,
  error,
  onChange,
  label,
  placeholder,
  type = "text",
  renderItem,
  data,
  editTag=false
}: Props) => {
  const styles = useStyles();
  console.log("VAlue",value)
  return (
    <Box style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
      <Typography className={styles.inputLabel}>{label}</Typography>
      <Box sx={{ bgcolor: "white", borderRadius: "8px", padding: "15px 20px" }}>
        <Select value={value} onChange={onChange} className={styles.dropdown__main}>
          {
            editTag &&  <option disabled selected value="-1" style={{marginLeft:"13px",fontSize:"1rem", fontFamily: "'Roboto', sans-serif",}}>Select Tag</option>
          }
         
          {
            data?.map(item=>renderItem(item))
          }
        </Select>
      </Box>
      {error && <Typography className={styles.errorText}>{error}</Typography>}
    </Box>
  );
};

export default CustomSelect;
