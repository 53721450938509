Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.searchKeywordApiUrl = "bx_block_keywordsearch/searches";
exports.applicationJsonContentType = "application/json";
exports.getAllProjects="bx_block_landingpage/projects";
exports.getNewProjectApplications="bx_block_landingpage/projects/admin_landing_page";
exports.getDataBySearchKeyword="bx_block_keywordsearch/searches/search";
exports.getTagList="bx_block_landingpage/projects/get_tags";
exports.getProjectByTagFilter="bx_block_filter_items/filtering/filter_by_tags";
exports.postApiMethodType = "POST";
exports.getApiMethodType = "GET";
exports.patchApiMethodType = "PATCH";
exports.deleteApiMethodType = "DELETE";
// Customizable Area End