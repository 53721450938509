import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { MsalContext } from "@azure/msal-react";
import { IDepartmentType, ITagType } from "./settings.types";
import CreateRestApiMessage from "../../../components/src/util/CreateRestApiMessage.web";
import { toast } from "react-toastify";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  businessExpandOpen: boolean;
  selectedBusinessUnit: IDepartmentType | null;
  createBusinessUnitModal: boolean;
  editBusinessUnitModal: boolean;
  createTagsModal: boolean;
  editTagsModal: boolean;
  tagsExpand: boolean;

  tagList: ITagType[];
  businessUnitList: IDepartmentType[];
  createTagLoading: boolean;
  editTagLoading: boolean;
  deleteTagLoading: boolean;
  getTagLoading: boolean;
  createBusinessUnitLoading: boolean;
  editBusinessUnitLoading: boolean;
  deleteBusinessUnitLoading: boolean;
  getBusinessUnitLoading: boolean;
  selectedTag: ITagType | null;
  isAdmin:boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {}

export default class SettingsPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  static contextType = MsalContext;
  apiCreateBusinessUnitCallId: string = "";
  apiEditBusinessUnitCallId: string = "";
  apiDeleteBusinessUnitCallId: string = "";
  apiGetBusinessUnitCallId: string = "";
  apiCreateTagCallId: string = "";
  apiEditTagCallId: string = "";
  apiDeleteTagCallId: string = "";
  apiGetTagCallId: string = "";
  apiLoggedInUserCallId:string="";
  // Customizable Area Start
      // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ]);

    this.state = {
      businessExpandOpen: false,
      selectedBusinessUnit: null,
      createBusinessUnitModal: false,
      editBusinessUnitModal: false,
      createTagsModal: false,
      editTagsModal: false,
      tagsExpand: false,
      tagList: [],
      businessUnitList: [],
      createBusinessUnitLoading: false,
      editBusinessUnitLoading: false,
      getBusinessUnitLoading: false,
      deleteBusinessUnitLoading: false,
      createTagLoading: false,
      editTagLoading: false,
      getTagLoading: false,
      deleteTagLoading: false,
      selectedTag: null,
      isAdmin:true,
      // Customizable Area Start
      // Customizable Area End
    };
    // Customizable Area Start
      // Customizable Area End
  }
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      // Customizable Area Start
      // Customizable Area End
      if (responseJson?.errors?.length > 0 && responseJson?.errors[0]?.token) {
        localStorage.removeItem("accessToken");
        this.navigateToLogin();
      }
      if (this.apiGetBusinessUnitCallId === apiRequestCallId) {
        this.handleGetBusinessUnitPayload(responseJson);
      }else if(this.apiLoggedInUserCallId === apiRequestCallId) {
        this.handleUserNavigation(responseJson?.is_admin);
      } else if (this.apiCreateBusinessUnitCallId === apiRequestCallId) {
        this.handleCreateBusinessUnitPayload(responseJson);
      } else if (this.apiEditBusinessUnitCallId === apiRequestCallId) {
        this.handleEditBusinessUnitPayload(responseJson);
      } else if (this.apiDeleteBusinessUnitCallId === apiRequestCallId) {
        this.handleDeletetBusinessUnitPayload(responseJson);
      } else if (this.apiGetTagCallId === apiRequestCallId) {
        this.handleGetTagListPayload(responseJson);
      } else if (this.apiCreateTagCallId === apiRequestCallId) {
        this.handleCreateTagPayload(responseJson);
      } else if (this.apiEditTagCallId === apiRequestCallId) {
        this.handleEditTagPayload(responseJson);
      } else if (this.apiDeleteTagCallId === apiRequestCallId) {
        this.handleDeleteTagPayload(responseJson);
      }
      // Customizable Area Start
      // Customizable Area End
    }
  }
  handleUserNavigation=(isAdmin:boolean)=>{
    this.setState({isAdmin:isAdmin})
    const isAuthenticated = this.context?.accounts.length > 0;
    if (!isAuthenticated) {
      this.navigateToLogin();
      return;
    }
    if (isAuthenticated && !isAdmin) {
      this.navigateToUserHomePage();
    }
  }
  handleGetBusinessUnitPayload = (responseJson: any) => {
    if (responseJson?.data?.length > 0) {
      this.setState({
        getBusinessUnitLoading: false,
        businessUnitList: responseJson?.data,
      });
    } else {
      this.setState({
        getBusinessUnitLoading: false,
        businessUnitList: [],
      });
    }
  };
  handleCreateBusinessUnitPayload = (responseJson: any) => {
    if (responseJson?.data?.id) {
      this.setState({
        createBusinessUnitLoading: false,
        createBusinessUnitModal: false,
      });
      toast.success("Business Unit Created");
      this.getAllBusinessUnit();
    } else {
      this.setState({
        createBusinessUnitLoading: false,
        createBusinessUnitModal: false,
      });
      const message =
        responseJson?.errors?.length > 0
          ? responseJson?.errors[0]?.name
          : "Business Unit Create Failed.";
      toast.error(message);
    }
  };
  handleEditBusinessUnitPayload = (responseJson: any) => {
    if (responseJson?.data?.id) {
      this.setState({
        editBusinessUnitLoading: false,
        editBusinessUnitModal: false,
      });
      toast.success("Business Unit Updated");
      this.getAllBusinessUnit();
    } else {
      this.setState({
        editBusinessUnitLoading: false,
        editBusinessUnitModal: false,
      });
      const message =
        responseJson?.errors?.length > 0
          ? responseJson?.errors[0]?.name
          : "Update Failed";
      toast.error(message);
    }
  };
  handleDeletetBusinessUnitPayload = (responseJson: any) => {
    if (responseJson?.message) {
      this.setState({
        deleteBusinessUnitLoading: false,
      });
      this.getAllBusinessUnit();
      toast.success("Business Unit Deleted.");
    } else {
      this.setState({
        deleteBusinessUnitLoading: false,
      });
      toast.error("Business Unit Delete Failed.");
    }
  };
  handleGetTagListPayload = (responseJson: any) => {
    if (responseJson?.data?.length > 0) {
      this.setState({
        getTagLoading: false,
        tagList: responseJson?.data,
      });
    } else {
      this.setState({
        getTagLoading: false,
        tagList: [],
      });
    }
  };
  handleCreateTagPayload = (responseJson: any) => {
    if (responseJson?.data?.id) {
      this.setState({
        createTagLoading: false,
        createTagsModal: false,
      });
      toast.success("Tag Created");
      this.getAllTagList();
    } else {
      const message =
        responseJson?.errors?.length > 0
          ? responseJson?.errors[0]?.name
          : "Tag Create Failed";
      this.setState({
        createTagLoading: false,
        createTagsModal: false,
      });
      toast.error(message);
    }
  };
  handleEditTagPayload = (responseJson: any) => {
    if (responseJson?.data?.id) {
      this.setState({
        editTagLoading: false,
        editTagsModal: false,
      });
      toast.success("Tag Updated");
      this.getAllTagList();
    } else {
      const message =
        responseJson?.errors?.length > 0
          ? responseJson?.errors[0]?.name
          : "Tag Create Failed";
      this.setState({
        editTagLoading: false,
        editTagsModal: false,
      });
      toast.error(message);
    }
  };
  handleDeleteTagPayload = (responseJson: any) => {
    if (responseJson?.message) {
      this.setState({
        deleteTagLoading: false,
      });
      this.getAllTagList();
      toast.success("Tag Deleted.");
    } else {
      this.setState({
        deleteTagLoading: false,
      });
      toast.error("Tag Delete Failed.");
    }
  };
  // Customizable Area Start
  // Customizable Area End
  async componentDidMount() {
    this.getLoggedInUser();
    this.getAllBusinessUnit();
    this.getAllTagList();
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

getLoggedInUser = () => {
    const header = {
      token: localStorage.getItem("accessToken"),
      "Content-Type": configJSON.applicationJsonContentType,
    };
    const requestMessage = CreateRestApiMessage({
      header,
      method: "GET",
      apiUrl: `account_block/accounts/admin_account`,
      body: null,
    });
    this.apiLoggedInUserCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  navigateToUserHomePage = () => {
    this.props.navigation.navigate("ApplicantLandingPage");
  };
  handleBuisnessExpand = () => {
    this.setState((prevState) => ({
      businessExpandOpen: !prevState.businessExpandOpen,
    }));
  };
  handleTagExpand = () => {
    this.setState((prevState) => ({
      tagsExpand: !prevState.tagsExpand,
    }));
  };
  handleChangeBusinessUnit = (e: any) => {
    const { businessUnitList } = this.state;
    const unitId = e.target?.value;
    const filteredBusinessUnit = businessUnitList?.filter(
      (item) => item?.id === unitId
    );
    this.setState({
      selectedBusinessUnit: filteredBusinessUnit[0],
    });
  };
  handleCreateBusinessModal = (open: boolean) => {
    this.setState({
      createBusinessUnitModal: open,
    });
  };
  handleEditBusinessModal = (open: boolean) => {
    this.setState({
      editBusinessUnitModal: open,
    });
  };
  handleCreateTagsModal = (open: boolean) => {
    this.setState({
      createTagsModal: open,
    });
  };
  handleEditTagsModal = (open: boolean) => {
    this.setState({
      editTagsModal: open,
    });
  };
  handleSelectTag = (item: ITagType) => {
    this.setState({
      editTagsModal: true,
      selectedTag: item,
    });
  };
  goBack = () => {
    this.props.navigation?.goBack();
  };
  createNewTag = (body: any) => {
    this.setState({
      createTagLoading: true,
    });
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: configJSON.tagApiUrl,
      body: JSON.stringify(body),
      method: configJSON.postApiMethodType,
    });
    this.apiCreateTagCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllTagList = () => {
    this.setState({
      getTagLoading: true,
    });
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: configJSON.tagApiUrl,
      body: null,
      method: configJSON.getApiMethodType,
    });
    this.apiGetTagCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  editTag = (body: any) => {
    this.setState({
      editTagLoading: true,
    });
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.tagApiUrl}/${this.state.selectedTag?.id}`,
      body: JSON.stringify(body),
      method: configJSON.patchApiMethodType,
    });
    this.apiEditTagCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteTag = (tagId: string) => {
    this.setState({
      deleteTagLoading: true,
    });
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.tagApiUrl}/${tagId}`,
      body: null,
      method: configJSON.deleteApiMethodType,
    });
    this.apiDeleteTagCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createNewBusinessUnit = (body: any) => {
    this.setState({
      createBusinessUnitLoading: true,
    });
    const header = {
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: configJSON.businessUnitApiURL,
      body: body,
      method: configJSON.postApiMethodType,
    });
    this.apiCreateBusinessUnitCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllBusinessUnit = () => {
    this.setState({
      getBusinessUnitLoading: true,
    });
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: configJSON.businessUnitApiURL,
      body: null,
      method: configJSON.getApiMethodType,
    });
    this.apiGetBusinessUnitCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  editBusinessUnit = (body: any) => {
    this.setState({
      editBusinessUnitLoading: true,
    });
    const id = this.state.selectedBusinessUnit?.id;
    const header = {
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.businessUnitApiURL}/${id}`,
      body: body,
      method: configJSON.patchApiMethodType,
    });
    this.apiEditBusinessUnitCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteBusinessUnit = (id: string) => {
    this.setState({
      deleteBusinessUnitLoading: true,
    });
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.businessUnitApiURL}/${id}`,
      body: null,
      method: configJSON.deleteApiMethodType,
    });
    this.apiDeleteBusinessUnitCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  navigateToLogin = () => {
    this.props.navigation.navigate("Login");
  };
  // Customizable Area Start
   // Customizable Area End
}
