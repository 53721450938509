import React, { useState } from 'react';

import {
  Button,
  Typography,
  // Customizable Area Start
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Checkbox,
  // Customizable Area End
  
} from "@material-ui/core";

import { CSSProperties } from 'react';
import CloseIcon from '@material-ui/icons/Close';

const PopupModal = () => {
    const [openPopupModel,setOpenPopupModel]=useState(localStorage.getItem("isFirstTime")==="false" ? false: true);
     const handleOnClose=()=>{
      localStorage.setItem("isFirstTime","false");
       setOpenPopupModel(false);
      }
      
  return (
    <Dialog 
    BackdropProps={{ style: { backgroundColor: "#D8D8D8", opacity: "0.5" } }}   PaperProps={{
    style: { borderRadius: 20,maxHeight:"unset", maxWidth: "588px" }
  }}
    aria-labelledby="customized-dialog-title"
    open={openPopupModel}
    >
      <CloseIcon
      style={webStyle.popupCloseIcon as CSSProperties}
      onClick={handleOnClose}
      />
    <DialogTitle id="customized-dialog-title"
  style={webStyle.popupTitleBox}
    >
      <img style={webStyle.popupModalImg} src="https://static.vecteezy.com/system/resources/thumbnails/004/578/955/small/testing-the-smartphone-application-and-developing-it-free-vector.jpg"></img>
      <Typography variant="h2" style={webStyle.popupModalHead as CSSProperties}> Welcome to the Nest Idea Platform</Typography>
    </DialogTitle>
    <DialogContent
     style={webStyle.popupContentBox}            
    >
      <Typography gutterBottom style={webStyle.popupModalPera as CSSProperties}>
        Here you can browse existing Nest projects to get inspired. You might find some projects similar to your idea. If you don't see any
        similar projects and you have an idea for a new Nest project you can simply submit your idea. We will get in touch with you to talk
        about it and discuss further steps.
      </Typography>
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox name="checkedA" />}
          label={<Typography style={webStyle.popupCheckTypography as CSSProperties}>I accept user guidelines notes.</Typography>}
        />
      </FormGroup>
    </DialogContent>
    <DialogActions style={webStyle.popupModalBtnBox}>
      <Button variant="contained" style={webStyle.popupModalBtnOrange as CSSProperties}  
      onClick={handleOnClose}
      >Let's Browse</Button>
    </DialogActions>
  </Dialog>
  )
}
const webStyle = {
    popupModalPera:{
      textAlign:'center',
      fontSize:'18px',
      lineHeight:'29px',
      color: '#999',
    },
    popupModalHead:{
      fontSize:'36px',
      lineHeight:'52px',
      color:'#171717',
      textAlign:'center'
    },
    popupCheckTypography:{
      fontSize:'18px',
      linHeight:'22px'
    },
    popupModalBtnBox:{
      margin:'0px auto'
    },
    popupModalBtnOrange:{
      background: '#E46E2A',
      height: '40px',
      color: '#fff',
      textTransform: 'capitalize',
      fontWeight: '500',
      padding: ' 9px 25px',
      fontSize: '12px',
      borderRadius: '8px',
      minWidth: '260px',
      marginBottom:'32px'
    },
    popupModalImg:{
      margin:'0 auto 14px',
      display:'block'
    },   
    popupTitleBox:{
      paddingInline:"20px",
      paddingTop: "40px"
    },
    popupContentBox:{
      paddingInline:"20px"
    },
    popupCloseIcon:{
      position: "absolute",
      right: "40px",
      top: "40px",
      fill: "#999999"
    }  
  };
  // Customizable Area End
  
  

export default PopupModal