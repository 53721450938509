Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings5";
exports.labelBodyText = "Settings5 Body";

exports.btnExampleTitle = "CLICK ME";
exports.tagApiUrl = "bx_block_settings/tags";
exports.businessUnitApiURL = "bx_block_settings/business_units";
exports.applicationJsonContentType = "application/json";
exports.postApiMethodType = "POST";
exports.getApiMethodType = "GET";
exports.patchApiMethodType = "PATCH";
exports.deleteApiMethodType = "DELETE";
// Customizable Area End
