import React from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { ITagType } from "../../../blocks/Settings5/src/settings.types";

interface Props {
  data?: ITagType[];
  error?: string;
  onChange: (tagId:string,checked:boolean) => void;
  label: string;
  placeholder: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputLabel_multiple: {
      color: "#666666",
      fontSize: "16px",
      fontWeight: 500,
    },
    errorText_multiple: {
      color: "red",
      fontSize: "14px",
      fontWeight: 500,
      marginTop: "5px",
    },
    dropdown__main_multiple: {
      fontSize: "14px",
      fontWeight: "bolder",
      width: "100%",
      background: "transparent",
      "&:hover": {
        borderBottom: "0px",
      },
      "&::after": {
        borderBottom: "0px !important",
      },
      "&::before": {
        borderBottom: "0px !important",
      },
      "&$selected": {
        borderBottom: "0px !important",
      },
      "&:focus": {
        background: "transparent",
      },
    },
  })
);
const CustomSelect = ({
  error,
  onChange,
  label,
  data,
}: Props) => {
  const styles = useStyles();


  return (
    <Box style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
      <Typography className={styles.inputLabel_multiple}>{label}</Typography>
      <Box
        sx={{ bgcolor: "white", borderRadius: "8px", padding: "15px 20px",display:"flex",alignItems:"center",flexWrap:"wrap"}}
      >
        {
          data?.map((item)=>(
            <div key={item?.id}>
             <FormControlLabel
                control={
                  <Checkbox
                    checked={item.checked?true:false}
                    onChange={(e)=>onChange(item.id,e.target.checked)}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={item?.attributes?.name}
              />
            </div>
          ))
        }
      </Box>
      {error && (
        <Typography className={styles.errorText_multiple}>{error}</Typography>
      )}
    </Box>
  );
};

export default CustomSelect;
