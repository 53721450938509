import React, { useEffect ,useState} from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  IconButton,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AppHeader from "./AppHeader.web";
import { browseProjectActiveImg, browseProjectImg, logoImg, newProjectActiveImg, newProjectImg, settingsActiveImg, settingsImg } from "../../../blocks/customform/src/assets";
import { avatarImg } from "../../../blocks/Settings5/src/assets";
import { useMsal } from "@azure/msal-react";
import {Link} from 'react-router-dom'


const loginRequest = {
  scopes: ["User.Read"],
};

const getSideBarList=(isAdmin:boolean)=>{
  return [
      {
        id:1,
        title:"New Idea Applications",
         icon:(active:boolean)=>active?newProjectActiveImg:newProjectImg,
        activeLinks:["/AdminLandingPage","/EditProject"],
        link:"/AdminLandingPage"
     }, 
     {
        id:2,
        title:"Browse Project Portfolio",
         icon:(active:boolean)=>active?browseProjectActiveImg:browseProjectImg,
        activeLinks:["/AdminBrowseProject"],
        link:"/AdminBrowseProject"
     }, 
      {
        id:3,
        title:"Settings",
         icon:(active:boolean)=>active?settingsActiveImg:settingsImg,
        activeLinks:["/SettingsPage"],
        link:"/SettingsPage"
     },
    
]
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100vh",
      overflow: "hidden",
      background: "#F2F2F2",
    },
    sidebarButton: {
      background: "transparent",
      borderRadius: "0px",
      width:"100%"
    },
  })
);

interface Props {
  children: any;
  title: string;
  backButton?:boolean;
  goBack?:()=>void;
  isAdmin:boolean;
  headerBackButton?:boolean;
}

const CustomLayout = ({ children, title,backButton=false ,goBack,isAdmin,headerBackButton=false}: Props) => {
  const styles = useStyles();

  const { accounts,instance } = useMsal();
  const [image, setImage] = useState<any>()

  const [isOk, setResponse] = useState<any>()
   
useEffect(() => {
    GetProfilePicture()
}, [])

  

const GetProfilePicture = async () => {
  try{
    const tokenResponse=await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        })
      if(!tokenResponse?.accessToken){
        return
      }
    const headers = new Headers();
    const bearer = `Bearer ${tokenResponse?.accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-type", 'image/jpeg');
    await fetch("https://graph.microsoft.com/v1.0/me/photo/$value", {
        method: "GET",
        headers: headers,
    })
    .then(response => {
        response.blob().then((data) => {
            const reader = new FileReader()
        reader.readAsDataURL(data)
        reader.onload = () => {
            const base64data = reader.result;
            
            setImage(base64data)
            setResponse(response.ok)
            
            }
        })
    })
  }catch(err){
     
  }
   
} 

const handleLogout = (logoutType: string = "popup") => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/Login",
        mainWindowRedirectUri: "/Login",
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/Login",
      });
    }
};
const handleGoBack=()=>{
   if(goBack){
    goBack();
   }
}
const isRouteMatched=React.useCallback((paths:string[]):boolean=>{
  const currentPath=window.location.pathname;
    let matched=false;
    paths.forEach(path=>{
      if(path===currentPath || currentPath.includes(path)){
        matched=true;
      }
    })
    return matched;
  },[window.location.pathname])
  const name=accounts[0]?.name;
  const sidebarList=getSideBarList(isAdmin);
  return (
    <Grid container className={styles.container}>
      {
        isAdmin && (<Grid item style={{ height: "100%",minWidth:"310px" }}>
        <Card
          elevation={0}
          style={{
            borderRadius: "0px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "30px 20px",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Link to={isAdmin?"/AdminLandingPage":"/ApplicantLandingPage"}>
            <img
              src={logoImg}
              alt={"logo"}
              style={{
                height: "40px",
                objectFit: "cover",
                margin: "10px 0px",
              }}
            />
            </Link>
            {
              backButton &&  (<IconButton
                className={styles.sidebarButton}
                onClick={handleGoBack}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "7px",
                    width: "100%",
                  }}
                >
                  <ArrowBackIcon/>
                  <Typography style={{color:"#999999"}}>Back</Typography>
                </Box>
              </IconButton>)
            }
           
            {sidebarList.map((item, index) => (
              <Link to={item?.link}  key={item.id} style={{textDecoration:"none",cursor:"pointer"}}>
              <IconButton
              
                className={styles.sidebarButton}
                style={
                  isRouteMatched(item?.activeLinks)
                    ? {
                        background: "#FBEADF",
                        borderRadius: "8px",
                      }
                    : {}
                }
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "7px",
                    width: "100%",
                  }}
                >
                  <img src={item?.icon(isRouteMatched(item?.activeLinks))} alt="sd_img" style={{width:"27px",height:"27px"}}/>
                  <Typography style={{ ...isRouteMatched(item?.activeLinks)?{color:"#E46E2A"}:{color:"#999999"},
                   whiteSpace: "nowrap"}}>{item?.title}</Typography>
                </Box>
              </IconButton>
              </Link>
            ))}
          </Box>
          <Box
            style={{
              display: "flex",
              padding: "10px 0px",
              alignItems: "center",
              justifyContent: "space-between",
              borderTop: "1px solid #E4E4E4",
            }}
          >
            <Box
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <img
                src={isOk?image:avatarImg}
                alt="User"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                {name}
              </Typography>
            </Box>
            <IconButton onClick={()=>handleLogout()}>
              <ExitToAppIcon htmlColor="#171F22" />
            </IconButton>
          </Box>
        </Card>
      </Grid>)
      }
      
      <Grid
        item
        style={isAdmin?{ overflowY: "auto", height: "100vh",width:"calc(100% - 312px)" }:{overflowY: "auto", height: "100vh",width:"100%"}}
        className="custom_scrollbar"
        id="main_container"
      >
        <AppHeader
          title={title}
          titleStyle={{
            marginLeft: "20px",
          }}
          logo={false}
          left={isAdmin?"312px":"0px"}
          optionButton={true}
          isAdmin={isAdmin}
          backButton={headerBackButton}
        />
        <Box sx={{ marginTop: "130px" }} />
        {children}
      </Grid>
    </Grid>
  );
};

export default CustomLayout;
