import React from "react";
import CustomFormController, {
  Props,
} from "./CreateApplicationFormController.web";
import { Box, Typography, MenuItem, Button } from "@material-ui/core";
import CustomTextInput from "../../../components/src/util/CustomTextInput.web";
import CustomSelect from "../../../components/src/util/CustomSelect.web";
import CustomTextArea from "../../../components/src/util/CustomTextArea.web";
import AppHeader from "../../../components/src/util/AppHeader.web";
import CustomMultiSelect from "../../../components/src/util/CustomMultiSelect.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import { IDepartmentType, ITagType } from "../../Settings5/src/settings.types";
import SelectAttatchment from "../../../components/src/util/SelectAttatchment.web";
export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
  }

  getSelectedStyle = (id: any): any => {
    const { tags } = this.state;
    if (tags?.includes(id)) {
      return {
        backgroundColor: "#E75420",
        color: "white",
      };
    }
    return {
      backgroundColor: "white",
    };
  };

  render() {
    const {
      title,
      description,
      businessUnit,
      ideaOwnerName,
      email,
      errors,
      tagList,
      businessUnitList,
      attatchment,
      createLoading,
      isAdmin,
    } = this.state;
    return (
      <Box sx={webStyle.container}>
        <AppHeader
          isAdmin={isAdmin}
          logo={true}
          optionButton={true}
          title={""}
        />
        <Box sx={{ marginTop: "130px" }} />
        <Box sx={{ width: "700px", margin: "0 auto", mt: 5 }}>
          <Typography
            component="h1"
            variant="h3"
            style={{
              fontSize: "36px",
              fontWeight: 600,
              color: "black",
              marginBottom: "25px",
            }}
          >
            Please complete the application form
          </Typography>
          <CustomTextInput
            data-testid="ideaTitleInput"
            label="Idea Title"
            placeholder="Idea Title"
            value={title}
            onChange={this.handleTitleChange}
            error={errors?.title}
          />
          <br />
          <CustomTextInput
            data-testid="ideaOwnerName"
            label="Idea Owner Name"
            placeholder="Idea Owner Name"
            value={ideaOwnerName}
            onChange={this.handleIdeaOwnerNameChange}
            error={errors?.ideaOwnerName}
          />
          <br />
          <CustomTextInput
            data-testid="emailAddressInput"
            label="Email Address"
            placeholder="Email Address"
            value={email}
            onChange={this.handleEmailChange}
            error={errors?.email}
          />
          <br />
          <CustomSelect
            data-testid="businessUnitSelect"
            label="Business Unit"
            placeholder="Business Unit"
            data={businessUnitList}
            value={businessUnit}
            onChange={this.handleBusinessUnitChange}
            error={errors?.businessUnit}
            renderItem={(item: IDepartmentType) => (
              <MenuItem data-testid="businessUnitItem" value={item?.id}>
                {item.attributes?.name}
              </MenuItem>
            )}
          />
          <br />
          <CustomTextArea
            data-testid="ideaDescriptionInput"
            label="Idea Description"
            placeholder="Please tell us about your idea.."
            rows={4}
            value={description}
            onChange={this.handleDescriptionChange}
            error={errors?.description}
          />
          <br />
          <CustomMultiSelect
            data-testid="tagsInput"
            label="Tags"
            placeholder="Tags"
            data={tagList}
            onChange={this.handleTagsChange}
          />
          {errors?.tags && (
            <Typography
              data-testid="tagError"
              style={{ color: "red", fontSize: "14px" }}
              component="p"
              variant="h6"
            >
              {errors?.tags}
            </Typography>
          )}
          <br />

          <br />
          <SelectAttatchment
            data-testid="select_attatchment"
            attatchment={attatchment}
            changeHandler={this.handleAttatchmentChange}
            removeHandler={this.handleRemoveAttatchment}
            error={errors?.attatchment}
          />
          <br />
          <div style={webStyle.devider} />
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              borderRadius: "8px",
            }}
          >
            <Button
              onClick={this.goBack}
              variant="contained"
              style={{
                background: "white",
                textTransform: "capitalize",
                width: "120px",
                boxShadow: "none",
                borderRadius: "8px",
              }}
            >
              Back
            </Button>
            <Button
              onClick={this.validateInput}
              data-testid="submitFormButton"
              variant="contained"
              color="primary"
              style={{
                textTransform: "capitalize",
                width: "120px",
                boxShadow: "none",
                borderRadius: "8px",
              }}
            >
              {createLoading ? (
                <CircularProgress
                  data-testid="submitLoading"
                  style={{ color: "white" }}
                  size={24}
                />
              ) : (
                "Submit Idea"
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
}

export const webStyle = {
  container: {
    bgcolor: "#F2F2F2",
    height: "100vh",
    overflow: "auto",
    paddingBottom: "50px",
  },
  logo: {
    width: "162px",
    height: "40px",
    objectFit: "cover",
  },
  inputLabel: {
    color: "#666666",
    fontSize: "16px",
    fontWeight: 500,
  },
  attatchmentBox: {
    marginTop: "10px",
    border: "2px dashed #E4E4E4",
    width: "100%",
    padding: "15px",
    background: "white",
    borderRadius: "8px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "10px",
  },
  devider: {
    height: "2px",
    width: "100%",
    marginTop: "15px",
    marginBottom: "15px",
    background: "#E4E4E4",
  },
};
