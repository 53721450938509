Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.sellerDetailsApiContentType = "application/json";
exports.sellersAPIEndPoint = "custom_form/seller_accounts";

exports.sellerDetailsAPIMethodPUT = "PUT";
exports.sellerDetailsAPIMethodPOST = "POST";
exports.sellerDetailsAPIMethodPATCH = "PATCH";
exports.getSellersAPIMethod = "GET";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customform";
exports.labelBodyText = "customform Body";
exports.errorMessageShopName = "Shop name is mandatory";
exports.errorMessageGstin = "GSTIN is mandatory";

exports.btnExampleTitle = "CLICK ME";

exports.congrats = "Congratulations!";
exports.formSubmitted = "Your form is submitted";
exports.sellerDetails = "Seller Details";
exports.headerApplicantFormTitle = "Application Form";
exports.tagApiUrl = "bx_block_settings/tags";
exports.businessUnitApiUrl = "bx_block_settings/business_units";
exports.createProjectApiUrl =
  "bx_block_custom_form/seller_accounts/create_custom_form";
exports.getSingleProjectApiUrl = "bx_block_landingpage/projects";
exports.editProjectApiUrl =
  "bx_block_custom_form/seller_accounts/update_custom_form";
exports.applicationJsonContentType = "application/json";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.patchApiMethodType = "PATCH";
// Customizable Area End
