import React, {CSSProperties} from "react";

import {
  Box,
  Typography,
  InputAdornment,
  // Customizable Area Start
  TextField,
  Grid,  
  // Customizable Area End  
} from "@material-ui/core";

// Customizable Area Start
import SideBar from "../../../components/src/SideBar";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { AppHeader } from "../../../components/src/AppHeader.web";
import SearchIcon from '@material-ui/icons/Search'; 
import ProjectCard from "../../../components/src/ProjectCard.web";
import CircularProgress from "@material-ui/core/CircularProgress";
const theme = createTheme({
  palette: {
    primary: {
      main: "#E46E2A",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import LandingPageController, {
  Props,
} from "./LandingPageController.web";

export default class AdminBrowseProject extends LandingPageController {
  constructor(props: Props) {
    super(props);
   // Customizable Area Start
    // Customizable Area End   
  }
  // Customizable Area Start
  navigateProduct = (id:any)=>{
    this.showProjectDetail(id);
  }  
  
  // Customizable Area End
  render() {
      // Customizable Area Start
    const {tagList,loading,searchResult}=this.state;
    const checkedList=tagList?.filter((tag:any)=>tag?.checked);
    const checkedTagListNames=checkedList?.map((tag)=>tag?.attributes?.name)?.join(",");
    let searchVal="";

    if(checkedTagListNames){
      searchVal=checkedTagListNames
    }else if(searchResult){
      searchVal=searchResult
    }else{
      searchVal="No Tag Selected"
    }

  // Customizable Area End
    return (
       // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.AdminBrowseMainWrapper}>          
        <SideBar 
          taglist={this.state.tagList} 
          checkHandler={this.checkHandler}
          clickOnLogo={()=>{this.handleOnRedirectToLandingPage()}}/>          
          <Box sx={webStyle.AdminBrowseWrapper}>
            <AppHeader heading={"Browse Project Portfolio"} />        
            <Box sx={webStyle.AdminBrowseContentCentre}>
              <Box sx={webStyle.AdminBrowseSearchBox}>
                <form autoComplete="off" style={webStyle.AdminBrowseFormInput}>
                  <TextField id="outlined-basic" placeholder="search" variant="outlined" style={webStyle.AdminBrowseInputField}
                  onChange={(event)=>this.handleOnSearch(event)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={webStyle.AdminBrowseInputAdornment}>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }} 
                    value={searchResult}
                  />
                       
                </form>
              </Box>
              <Box style={webStyle.AdminBrowseCardData as CSSProperties}>
                <Box>
                  <Typography variant="h5" style={webStyle.AdminBrowseTitlePort as CSSProperties}>
                    Search Results {loading &&  <CircularProgress  style={{color:"#E46E2A"}} size={24}/>}
                  </Typography>
                  <Typography style={webStyle.AdminBrowseSubTitle as CSSProperties}>
                    {this.state?.landingPageProjects?.length} results for {searchVal}
                  </Typography>
                </Box>
                <ProjectCard ProjectData={this.state?.landingPageProjects} navigateProduct={(id:any)=>this.navigateProduct(id)}  loadMore={this.getAllProjects} />
               
              </Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
       // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  AdminBrowseMainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    background: "#fff",
    overflow: 'hidden',
    height: 'calc(-33px + 100vh)',
  },
  AdminBrowseWrapper: {
    background: '#F2F2F2',
    width: 'calc(100% - 312px)',
    fontSize: '14px',
    marginLeft:'312px'
  },
  AdminBrowseContentCentre: {
    padding: '24px 32px',
  },
  AdminBrowseSearchBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px',
    '& fieldset': {
      boxShadow: '0px 6px 17px rgba(228, 110, 42, 0.05)',
      borderColor: 'transparent !important',
    },
    '&:hover fieldset': {
      borderColor: 'transparent !important',
    },
  },
  AdminBrowseInputField: {
    background: '#fff',
    minWidth: '450px',
    borderRadius: '20px',
  },
  AdminBrowseFormInput: {
    marginBottom: '0px',
    display: 'flex',
    gap: '10px',
  },
  AdminBrowseTitlePort: {
    fontSize: '20px',
    fontWeight: '400',
    display:"flex",
    alignItems:"center",
    gap:"10px",
    marginBottom: '15px',
    color: '#171717'
  },
  AdminBrowseSubTitle: {
    fontSize: '18px',
    fontWeight: '400',
    color: '#66676A',
  },
  AdminBrowseCardData: {
    overflowY: 'auto',
    height: 'calc(100vh - 237px)',
    overflowX: 'hidden',
  },
  AdminBrowseCards: {
    marginTop: '20px',
  },
  AdminBrowseInputAdornment:{
    opacity: 0.5,
    '&.MuiOutlinedInput-root:hover': {
      borderColor: "transparent", outline: 'none',
    },
  }
};
 // Customizable Area End
