import {
  Button,
  createStyles,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
  Box,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import SelectCoverImage from "./SelectCoverImage.web";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submitButton: {
      textTransform: "capitalize",
      borderRadius: "15px",
      boxShadow: "none",
      minWidth: "120px",
    },
    ashButton: {
      borderRadius: "15px",
      boxShadow: "none",
      textTransform: "capitalize",
      fontWeight: 400,
      color: "black",
      minWidth: "120px",
      "&:hover": {
        boxShadow: "none",
      },
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      gap: "15px",
      justifyContent: "end",
    },
  })
);

interface Props {
  edit: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  actionLoading?: boolean;
  actionHandler?:any;
  businessUnit?:string;
  coverImage?:string|null;
}

export default function BusinessUnitModal({
  open,
  setOpen,
  edit,
  actionLoading,
  actionHandler,
  businessUnit,
  coverImage
}: Props) {
  const styles = useStyles();
  const [businessUnitName, setBusinessUnitName] = React.useState<string>(edit?businessUnit as string:"");
  const [image, setImage] = React.useState<any>(edit?coverImage as string:null);
  const [errors,setErrors]=React.useState<any>({})

  const handleSubmit=()=>{
    let issue=false;
    let newError:any={}
     if(!businessUnitName){
      newError.businessUnitName="Please enter business unit name"
      issue=true;
     }
     if(image===null){
       newError.image="Please choose cover image";
       issue=true;
     }

     if(image!==null && image?.name){
      const fileSize = image?.size / 1024;
      const MAX_ATTATCHMENT_SIZE = 30 * 1024;
        if (fileSize > MAX_ATTATCHMENT_SIZE) {
          issue=true;
          newError.image = `File exceeds 30MB limit please compress and try again`;
        }
     }
     if(issue){
       setErrors(newError)
     }else{
      const formData = new FormData();
      if (image?.name) {
         formData.append("business_unit[cover_image]",image)
      }
      formData.append("business_unit[name]", businessUnitName);
       actionHandler(formData)
       setErrors({})
     }
  }
  const handleImageChange=(e:any)=>{
     setImage(e.target.files[0])
  }

  const handleRemoveImage=()=>{
     setImage(null)
  }
  const getButtonText = () => {
    if (edit) {
      return "Update";
    } else {
      return "Save";
    }
  };

  return (
    <Modal
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Paper
        style={{
          width: "680px",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <Typography data-testid="businessmodal-title" variant="h5" style={{ fontWeight: 600, fontSize: "22px" }}>
          {edit ? "Update Business Unit" : "Create Business Unit"}
        </Typography>

        <Box sx={{ py: 3 }}>
          <TextField
            data-testid="businessmodal-departmentet"
            fullWidth
            label="Department"
            variant="outlined"
            value={businessUnitName}
            onChange={(e) => setBusinessUnitName(e.target.value)}
            style={{ marginBottom: "10px" }}
            error={errors?.businessUnitName}
            helperText={errors?.businessUnitName}
          />
          <br/>
          <SelectCoverImage
            changeHandler={handleImageChange}
            error={errors?.image}
            image={image}
            removeHandler={handleRemoveImage}
            />
        </Box>

        <Box className={styles.buttonContainer}>
          <Button
            data-testid="businessmodal-cancelbtn"
            onClick={() => setOpen(false)}
            variant="contained"
            className={styles.ashButton}
          >
            Cancel
          </Button>
          <Button
            data-testid="businessmodal-savebtn"
            onClick={handleSubmit}
            disabled={actionLoading}
            variant="contained"
            color="primary"
            className={styles.submitButton}
          >
            {actionLoading ? <CircularProgress size={24} /> : getButtonText()}
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
}
