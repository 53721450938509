import React from "react";
import { Box, Typography } from "@material-ui/core";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import AttatchmentItem from "./AttatchmentItem.web";
import Dropzone from 'react-dropzone'
interface Props {
  attatchment: any[];
  changeHandler: any;
  removeHandler: (id:string) => void;
  error: string;
  label?:boolean;
}

const SelectAttatchment = ({
  attatchment,
  changeHandler,
  removeHandler,
  error,
  label=true
}: Props) => {

  let fileList:any[]=[];

  if(attatchment?.length>0){
    attatchment.forEach((file:any)=>{
        fileList.push(file)
    })
  }
  

  return (
    <Box>
      <Dropzone onDrop={acceptedFiles => changeHandler(acceptedFiles)} noClick={true}>
      {({getRootProps, getInputProps}) => (
        <section>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <>
    {
       label && <Typography 
                  style={{ color: "#666666",
                  fontSize: "16px",
                  fontWeight: 500}}
                >
                  File Attachments
                </Typography>
    }

          <Box
            style={{
              marginTop: "10px",
              border: "2px dashed #E4E4E4",
              width: "100%",
              padding: "15px",
              background: "white",
              borderRadius: "8px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {
              attatchment?.length!==0 && (
                 <Box style={{display:"flex",flexDirection:"column",justifyContent:"center",gap:"15px",width:"80%",alignItems:"center"}}>
                <Box style={{display:"flex",flexDirection:"column",gap:"4px",background:"#F2F2F2",paddingBottom:"4px",width:"100%"}}>
                {
                  fileList?.map((item:any,index:number)=>{
                    const size=(item?.size / 1024)/1024;
                    const fileSize=(Math.round(size * 100) / 100).toFixed(2)
                     const fileType = item?.name?.split('.')?.pop();
                    return(
                       <AttatchmentItem
                        id={item?.name}
                        key={index}
                        clickable={false}
                        deletable={true}
                        fileName={item?.name}
                        fileSize={fileSize}
                        fileType={fileType}
                        radius={0}
                        deleteHandler={removeHandler}
                      />
                    )
                  })
                }
                </Box>
               </Box>
              )
            }
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  cursor: "pointer",
                  gap: "10px",
                }}
                htmlFor="fileInput"
              >
                <input
                  multiple
                  data-testid="attatchmentInput"
                  onChange={(e:any)=>changeHandler(e.target.files)}
                  id="fileInput"
                  accept=".pdf, .doc, .xls, .xlsx, .docx ,.odf ,.ppt ,.png, .jpg"
                  type="file"
                  style={{ visibility: "hidden" }}
                />
                <label
                  htmlFor="fileInput"
                  style={{ transform: "rotate(180deg)" }}
                >
                  <SaveAltIcon />
                </label>
                <Typography
                  style={{
                    color: "black",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  Upload File Attachments
                </Typography>
                <Typography
                  style={{
                    color: "#666666",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  PDF, WORD, EXCEL, PNG or JPG
                </Typography>
              </label>
          </Box>
          {error && (
            <Typography
              style={{ color: "red", fontSize: "14px" }}
              component="p"
              variant="h6"
            >
              {error}
            </Typography>
          )}
    </>
          </div>
        </section>
      )}
    </Dropzone>
    </Box>

  );
};

export default SelectAttatchment;