import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { MsalContext } from "@azure/msal-react";
import CreateRestApiMessage from "../../../components/src/util/CreateRestApiMessage.web";
// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");

const loginRequest = {
  scopes: ["User.Read", "Directory.Read.All"],
};

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  loading: boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {}

export default class MicrosoftLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  static contextType = MsalContext;

  createAccountAPICallId: any;
  apiGetUserRolesAndPermissionsCallId: string = "";
  apiLoginUserCallId: string = "";
  apiUserRolesCallId: string = "";
  mssUser: any;
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area Start
      // Customizable Area End
    ]);

    this.state = {
      loading: false,
      // Customizable Area Start
      // Customizable Area End
    };
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson === undefined) {
        return;
      }
      if (responseJson?.errors?.length > 0 && responseJson?.errors[0]?.token) {
        localStorage.removeItem("accessToken");
        return;
      }
      if (this.apiUserRolesCallId === apiRequestCallId) {
        this.handleUserNavigation(responseJson?.is_admin);
      }
      if (this.apiGetUserRolesAndPermissionsCallId === apiRequestCallId) {
        this.handleUserRolesAndPermissionPayload(responseJson);
      } else if (this.apiLoginUserCallId === apiRequestCallId) {
        this.handleLoginPayload(responseJson);
      }
      // Customizable Area Start
      // Customizable Area End
    }
  }

  handleUserRolesAndPermissionPayload = (responseJson: any) => {
    if (responseJson?.value) {
      const account = this.context?.accounts[0];
      const groups = responseJson.value;
      const allGroups = groups?.map((group: any) => group?.displayName);
      const bodyPayload = {
        data: {
          attributes: {
            email: account?.username,
            sso_user_id: account?.localAccountId,
            user_groups: allGroups,
          },
        },
      };
      this.loginMicrosoftUserToApplication(bodyPayload);
    } else {
      this.setState({ loading: false });
    }
  };
  handleLoginPayload = (responseJson: any) => {
    // Customizable Area Start
    // Customizable Area End
    this.setState({ loading: false });
    if (responseJson?.meta?.token) {
      localStorage.setItem("accessToken", responseJson?.meta?.token);
      localStorage.setItem("isAdmin", responseJson?.meta?.is_admin);
      this.navigateToHomePage(responseJson?.meta?.is_admin);
    }
  };
  handleUserNavigation = (isAdmin: boolean) => {
    // Customizable Area Start
    // Customizable Area End
    const isAuthenticated = this.context?.accounts.length > 0;
    if (isAuthenticated) {
      this.navigateToHomePage(isAdmin);
    }
  };
  async componentDidMount() {
    this.getUserRoles();
  }

  handleMssLogin = (loginType: string = "popup") => {
    const instance = this.context?.instance;
    if (loginType === "popup") {
      instance
        .loginPopup(loginRequest)
        .then((res: any) => {
          if (res?.accessToken) {
            this.fetchUserRolesAndPermissions(res?.accessToken);
          }
        })
        .catch((e: any) => {
          alert("Login Failed.");
        });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e: any) => {
        alert("Login Failed.");
      });
    }
  };
  fetchUserRolesAndPermissions = (accessToken: string) => {
    this.setState({
      loading: true,
    });
    const header = {
      Authorization: `Bearer ${accessToken}`,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUserRolesAndPermissionsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAzureRolesApiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  loginMicrosoftUserToApplication = (body: any) => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.loginUserApiUrl}`,
      body: JSON.stringify(body),
      method: configJSON.postApiMethodType,
    });
    this.apiLoginUserCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getUserRoles = () => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: `account_block/accounts/admin_account`,
      body: null,
      method: "GET",
    });
    this.apiUserRolesCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  navigateToHomePage = (isAdmin: boolean) => {
    this.props.navigation.navigate(
      isAdmin ? "AdminLandingPage" : "ApplicantLandingPage"
    );
  };
  // Customizable Area Start
  // Customizable Area End
}
