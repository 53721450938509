Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getApiMethodType = "GET";
exports.getAzureRolesApiUrl = "https://graph.microsoft.com/v1.0/me/memberOf";
exports.loginUserApiUrl = "bx_block_login/logins/sso_login";
exports.postApiMethodType = "POST";
exports.applicationJsonContentType = "application/json";
// Customizable Area End
