// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker from '../../components/src/registerServiceWorker';
import { createTheme, ThemeProvider } from '@material-ui/core';
const theme = createTheme({
  typography: {
    fontFamily: ['DM Sans', 'sans-serif'].join(',')
  },
  palette: {
    primary: {
      main: '#E46E2A'
    }
  }
});

ReactDOM.render(
  <Router>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Router>,
  document.getElementById('root')
);
registerServiceWorker();
