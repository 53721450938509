// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import HomeScreen from '../../components/src/HomeScreen';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import MicrosoftLoginScreen from '../../blocks/social-media-account-login/src/MicrosoftLogin.web';
import SaveAsPdf from '../../blocks/saveaspdf/src/SaveAsPdf';
import CfNestCalendarApi from '../../blocks/CfNestCalendarApi/src/CfNestCalendarApi';
import BreadcrumbNavigation from '../../blocks/BreadcrumbNavigation/src/BreadcrumbNavigation';
import ProposalGeneration2 from '../../blocks/ProposalGeneration2/src/ProposalGeneration2';
import KeywordSearch from '../../blocks/KeywordSearch/src/KeywordSearch';
import Customform from '../../blocks/customform/src/Customform';
import CreateProject from '../../blocks/customform/src/CustomForm.web';
import CustomFormSubmit from '../../blocks/customform/src/CustomFormSubmit.web';
import BulkUploading from '../../blocks/bulkuploading/src/BulkUploading';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import FormApprovalWorkflow from '../../blocks/FormApprovalWorkflow/src/FormApprovalWorkflow';
import Settings5 from '../../blocks/Settings5/src/Settings5';
import SettingsPage from '../../blocks/Settings5/src/SettingsPage.web';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import TermsAndConditions3 from '../../blocks/TermsAndConditions3/src/TermsAndConditions3';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
import ProjectPortfolio from '../../blocks/ProjectPortfolio/src/ProjectPortfolio';
import ProjectDetails from '../../blocks/ProjectPortfolio/src/ProjectDetails.web';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import AdminConsole from '../../blocks/AdminConsole/src/AdminConsole';
import ApiIntegration from '../../blocks/apiintegration/src/ApiIntegration';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import EmailNotifications2 from '../../blocks/EmailNotifications2/src/EmailNotifications2';
import AdminLandingPage from '../../blocks/landingpage/src/AdminLandingPage.web';
import AdminBrowseProject from '../../blocks/landingpage/src/AdminBrowseProject.web';
import ApplicantLandingPage from '../../blocks/landingpage/src/ApplicantLandingPage.web';
import EditProject from '../../blocks/customform/src/EditProject.web'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { msalConfig } from './authConfig';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

const msalInstance = new PublicClientApplication(msalConfig);

const routeMap = {
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  Login: {
    component: MicrosoftLoginScreen,
    path: '/Login'
  },
  SaveAsPdf: {
    component: SaveAsPdf,
    path: '/SaveAsPdf'
  },
  CfNestCalendarApi: {
    component: CfNestCalendarApi,
    path: '/CfNestCalendarApi'
  },
  BreadcrumbNavigation: {
    component: BreadcrumbNavigation,
    path: '/BreadcrumbNavigation'
  },
  ProposalGeneration2: {
    component: ProposalGeneration2,
    path: '/ProposalGeneration2'
  },
  KeywordSearch: {
    component: KeywordSearch,
    path: '/KeywordSearch'
  },
  Customform: {
    component: Customform,
    path: '/Customform'
  },
  CreateProject: {
    component: CreateProject,
    path: '/CreateProject'
  },
  EditProject: {
    component: EditProject,
    path: '/EditProject/:projectId'
  },
  CustomFormSubmit: {
    component: CustomFormSubmit,
    path: '/CustomFormSubmit'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  FormApprovalWorkflow: {
    component: FormApprovalWorkflow,
    path: '/FormApprovalWorkflow'
  },
  Settings5: {
    component: Settings5,
    path: '/Settings5'
  },
  SettingsPage: {
    component: SettingsPage,
    path: '/SettingsPage'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  TermsAndConditions3: {
    component: TermsAndConditions3,
    path: '/TermsAndConditions3'
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions'
  },
  ProjectPortfolio: {
    component: ProjectPortfolio,
    path: '/ProjectPortfolio'
  },
  ProjectDetails: {
    component: ProjectDetails,
    path: '/ProjectDetails/:id'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  AdminConsole: {
    component: AdminConsole,
    path: '/AdminConsole'
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: '/ApiIntegration'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  EmailNotifications2: {
    component: EmailNotifications2,
    path: '/EmailNotifications2'
  },
  AdminLandingPage: {
    component: AdminLandingPage,
    path: '/AdminLandingPage'
  },
  AdminBrowseProject: {
    component: AdminBrowseProject,
    path: '/AdminBrowseProject'
  },
  ApplicantLandingPage: {
    component: ApplicantLandingPage,
    path: '/ApplicantLandingPage'
  },

  Home: {
    component: MicrosoftLoginScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <MsalProvider instance={msalInstance}>
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
          <ToastContainer theme="dark" />
        </MsalProvider>
      </View>
    );
  }
}

export default App;
