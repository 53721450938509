Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProjectPortfolio";
exports.labelBodyText = "ProjectPortfolio Body";

exports.getProjectDetailApiURL = "bx_block_landingpage/projects";
exports.getProjectDetailMethodType = 'GET';
exports.applicationJsonContentType = "application/json";
exports.getLogginedUserApiURL = 'account_block/accounts/admin_account'


exports.btnExampleTitle = "CLICK ME";
// Customizable Area End