import React from "react";
import MicrosoftLoginController, {
  Props,
} from "./MicrosoftLoginController.web";
import { Box, Typography } from "@material-ui/core";
import { logoImg } from "../../customform/src/assets";
import { microsoftLogoImg } from "./assets";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import CircularProgress from "@material-ui/core/CircularProgress";
class MicrosoftLoginScreen extends MicrosoftLoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {loading}=this.state;
    return (
      <Box style={{ ...webStyle.container, position: "relative" }}>
        <img
          src={logoImg}
          alt="logo"
          style={{
            width: "305px",
            height: "74px",
            objectFit: "cover",
            position: "absolute",
            top: "25px",
            left: "25px",
          }}
        />
        <Box sx={webStyle.loginBox}>
          <Typography
            variant="h3"
            component="h1"
            style={{
              fontWeight: 700,
              fontSize: "48px",
              marginBottom: "20px",
              marginTop: "7px",
            }}
          >
            Login
          </Typography>
          {/* login button  */}
          <Box
            data-testid="microsoftLoginButton"
            onClick={() => this.handleMssLogin()}
            style={{
              padding: "15px 20px",
              borderRadius: "10px",
              cursor: "pointer",
              textTransform: "capitalize",
              width: "100%",
              border: "1px solid #E46E2A",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                cursor: "pointer",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                {loading && <CircularProgress size={24}/>}
                <img src={microsoftLogoImg} alt="microsoft logo" />
                <Typography
                  component="h6"
                  variant="h5"
                  style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  Login With Microsoft
                </Typography>
              </Box>
              <ArrowRightAltIcon />
            </Box>
          </Box>
        </Box>
        {/* <Box sx={webStyle.subParent} /> */}
      </Box>
    );
  }
}

export default MicrosoftLoginScreen;

const webStyle = {
  container: {
    background:
      "linear-gradient(90deg, rgba(251,234,222,1) 0%, rgba(226,227,227,1) 100%)",
    height: "100vh",
    width: "100%",
    padding: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  subParent: {
    height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loginBox: {
    width: "420px",
    padding: "34px",
    bgcolor: "white",
    borderRadius: "20px",
  },
};
