import React from "react";

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Card,
  Typography,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    projectImage: {
      height: "140px",
      width: "100%",
      objectFit: "cover",
    },
    projectOwnerText: {
      color: "#314346",
      fontWeight: 400,
      fontSize: "12px",
      marginBottom: "5px",
    },
    projectTitle: {
      color: "#171717",
      fontSize: "15px",
      fontWeight: "bolder",
      marginBottom: "5px",
      
    },
    projectPrice: {
      color: "#E46E2A",
      fontSize: "20px",
      fontWeight: 700,
      marginBottom: "5px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",

    },
    projectYear: {
      color: "#999999",
      fontSize: "18px",
      fontWeight: 700,
    },
    projectDescription: {
      color: "#999999",
      fontSize: "16px",
      fontWeight: 500,
      marginBottom: "5px",
      overflow: "hidden",
      display: "-webkit-box",
      textOverflow: "ellipsis",
      whiteSpace: "normal",
      WebkitLineClamp: 5,
      WebkitBoxOrient: "vertical",
      msTextOverflow: "ellipsis",  
    },
  })
);

interface Props {
  projectData: any;
  navigation: any; 
  getProjectDetail: (projectId: any) => void;
}
const projectImage =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIB0jQ8BfaxJS75ytpE4_G628jcDixcjJH6J-ISoxgNQ&s";
const ProjectItem = ( {projectData, navigation, getProjectDetail}: Props ) => {
  const data =projectData
  const styles = useStyles();
  const  projectId = parseInt(data?.id)

  const fetchProjectData = (id: number) => {
    navigation.navigate('ProjectDetails', {id})
    getProjectDetail(id)
  }
  return (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
      <Card elevation={0} 
        style={{ borderRadius: "10px", position: "relative", maxHeight: 400, minHeight: 400, height: "100%" }}>
        <img
          src={data.attributes?.cover_image?.url? data.attributes.cover_image?.url : projectImage}
          alt="Project Image"
          className={styles.projectImage}
        />
        <Box sx={{ px: 4, py: 1 }}>
              <Typography
                className={styles.projectOwnerText}
                component="p"
                variant="h6"
              >
                {data?.attributes?.idea_owner_name}
              </Typography>
              <Typography
                className={styles.projectPrice}
                component="h1"
                variant="h1"
              >
                {data?.attributes?.idea_title}
              </Typography>
          <Typography
            className={styles.projectDescription}
            component="p"
            variant="h6"
          >
           {data?.attributes?.idea_description}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => fetchProjectData(projectId)}
            style={{
              textTransform: "capitalize",
              boxShadow: "none",
              borderRadius: "8px",
              width:"97%",
              position:"absolute",
              left:"7px",
              right:"7px",
              bottom:"10px"
            }}
          >
            Show Details
          </Button>
        </Box>
      </Card>
    </Grid>
  );
};

export default ProjectItem;
