import React from "react";
import { Box, Typography } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
interface Props {
  image: any;
  changeHandler: any;
  removeHandler: () => void;
  error: string;
}
const SelectCoverImage = ({
  image,
  changeHandler,
  removeHandler,
  error,
}: Props) => {
  let imageUrl: string = "";
  if (image!==null && typeof image==="string") {
    imageUrl = image;
  } else if (image !== null && image?.name) {
    imageUrl = URL.createObjectURL(image);
  }
  
  return (
    <>
      <Box
        style={{
          background: "white",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
          padding: "15px",
          border: "2px dashed #E4E4E4",
        }}
      >
        {image === null ? (
          <label
            htmlFor="imageInput"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "7px",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              marginBottom: "10px",
            }}
          >
            <input
              data-testid="imageInput"
              onChange={changeHandler}
              id="imageInput"
              accept=".png, .jpg, .jpeg"
              type="file"
              style={{ visibility: "hidden" }}
            />
            <SaveAltIcon style={{ transform: "rotate(180deg)" }} />
            <Typography
              style={{
                color: "black",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              Upload Cover Image
            </Typography>
            <Typography
                  style={{
                    color: "#666666",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                PNG,JPG or JPEG only - optimal resolution is 320 x 158 pixels
            </Typography>
          </label>
        ) : (
          <img
            data-testid="selected_image_view"
            style={{ width: "90%", height: "200px", objectFit: "cover" }}
            src={imageUrl}
            alt="cover_image"
          />
        )}
        {image !== null && (
          <Box
            data-testid="removeImage"
            onClick={removeHandler}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "2",
              cursor: "pointer",
            }}
          >
            <DeleteOutlineOutlinedIcon style={{ color: "rgba(0,0,0,0.54)" }} />
            <Typography
              style={{
                color: "#666666",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              Delete Image
            </Typography>
          </Box>
        )}
      </Box>
      {error && (
        <Typography
          style={{ color: "red", fontSize: "14px" }}
          component="p"
          variant="h6"
        >
          {error}
        </Typography>
      )}
    </>
  );
};

export default SelectCoverImage;
