import { Card, Box, Typography,IconButton } from "@material-ui/core";
import { logoImg } from "../../../blocks/customform/src/assets";
import React from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
interface Props {
  logo?: boolean;
  title: string;
  optionButton?: boolean;
  titleStyle?: any;
  left?: string;
  positionHeader?: boolean;
  isAdmin: boolean;
  backButton?:boolean;
}

const AppHeader = ({
  logo,
  title,
  titleStyle,
  left = "0",
  positionHeader,
  isAdmin,
  backButton=false
}: Props) => {
  return (
    <Card
      style={{
        background: "#F2F2F2",
        borderBottom: "1px solid #E4E4E4",
        display: "flex",
        alignItems: "center",
        gap: "20px",
        justifyContent: "space-between",
        padding: "24px 12px",
        position: positionHeader ? "relative" : "fixed",
        left: left,
        right: "0",
        borderRadius: "0px",
        zIndex: 100,
      }}
      elevation={0}
    >
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "30px",
        }}
      >
        {
          backButton && (
            <Link to={isAdmin ? "/AdminLandingPage" : "/ApplicantLandingPage"}>
            <IconButton
            style={{ background: "transparent", marginLeft: "10px" }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "7px",
              }}
            >
              <ArrowBackIcon />
              <Typography>Back</Typography>
            </Box>
          </IconButton>
          </Link>
          )
        }
        {logo && (
          <Link to={isAdmin ? "/AdminLandingPage" : "/ApplicantLandingPage"}>
            <img
              style={{
                height: "40px",
                objectFit: "cover",
              }}
              src={logoImg}
              alt="logo"
            />
          </Link>
        )}

        <Typography
          component="h1"
          variant="h3"
          style={{
            ...titleStyle,
            fontSize: "32px",
            fontWeight: "600",
            color: "black",
          }}
        >
          {title}
        </Typography>
      </Box>
    </Card>
  );
};

export default AppHeader;
