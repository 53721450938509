import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CreateRestApiMessage from "../../../components/src/util/CreateRestApiMessage.web";

import { MsalContext } from "@azure/msal-react";
export const configJSON = require("./config");
import { pdfImg, docImg, imgFileImg } from "./assets";

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  title: string;
  submittedDate: string;
  businessUnit: string;
  ideaOwnerName: string;
  tags: any[];
  email: string;
  description: string;
  errors: any;
  similarProjects: any[];
  documents: any[];
  isAdmin: boolean;
  canEdit?:boolean;
  // /*   isAdmin:boolean; */
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ProjectDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  static contextType = MsalContext;
  projectDetailCallId: string = "";
  apiGetLoggedInUserCallId: string = "";
  ref: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      title: "",
      submittedDate: "",
      businessUnit: "",
      ideaOwnerName: "",
      tags: [],
      email: "",
      description: "",
      errors: {},
      similarProjects: [],
      documents: [],
      isAdmin: localStorage?.getItem("isAdmin") === "true" ? true : false,
      canEdit:false,
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (response?.errors?.length > 0 && response?.errors[0]?.token) {
        localStorage.removeItem("accessToken");
        this.navigateToLogin();
      }
      if (apiRequestCallId === this.projectDetailCallId) {
        const projectData = response?.data?.attributes;
        const date = new Date(projectData?.submitted_date);
        const submittedDate = `${date?.getDate()}/${
          date?.getMonth() + 1
        }/${date?.getFullYear()}`;
        this.setState({
          title: projectData?.idea_title,
          submittedDate: submittedDate,
          businessUnit: projectData?.business_unit?.name,
          ideaOwnerName: projectData?.idea_owner_name,
          tags: projectData?.tags,
          description: projectData?.idea_description,
          similarProjects: projectData?.similar_projects?.data,
          documents: projectData?.documents,
          canEdit: projectData?.can_edit,
        });
      }

      if (apiRequestCallId === this.apiGetLoggedInUserCallId) {
        this.handleUserNavigation(response?.is_admin);
      }
    }
    // Customizable Area End
  }

  handleUserNavigation = (isAdmin: boolean) => {
    this.setState({ isAdmin: isAdmin });
    const isAuthenticated = this.context?.accounts.length > 0;
    if (!isAuthenticated) {
      this.navigateToLogin();
    }
  };

  navigateToLogin = () => {
    this.props.navigation.navigate("Login");
  };

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getLoggedInUser();
    const projectId = this.props.navigation.getParam("id");
    this.getProjectDetail(projectId);
    // Customizable Area End
  }

  navigateToEditProject = () => {
    const projectId = this.props.navigation.getParam("id");
    this.props.navigation.navigate("EditProject", { projectId });
  };

  img = (fileType: any) => {
    if (fileType == "pdf") {
      return pdfImg;
    } else if (fileType == "doc" || fileType == "ppt" || fileType == "docx") {
      return docImg;
    } else {
      return imgFileImg;
    }
  };

  getLoggedInUser = () => {
    const header = {
      token: localStorage.getItem("accessToken"),
      "Content-Type": configJSON.applicationJsonContentType,
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.getLogginedUserApiURL}`,
      method: "GET",
      body: null,
    });
    this.apiGetLoggedInUserCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  };

  getProjectDetail = (projectId: any) => {
    const mainContainer = document.getElementById("main_container");
    if (mainContainer) {
      mainContainer.scrollTop = 0;
    }
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("accessToken"),
    };

    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.getProjectDetailApiURL}/${projectId}`,
      body: null,
      method: configJSON.getProjectDetailMethodType,
    });
    this.projectDetailCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area Start
  navigateToLoginPage = () => {
    this.props.navigation.navigate("Login");
  };
  // Customizable Area End
}
