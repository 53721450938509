import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  FlatTagType,
  IDepartmentType,
  ITagType,
} from "../../Settings5/src/settings.types";
import CreateRestApiMessage from "../../../components/src/util/CreateRestApiMessage.web";
import { MsalContext } from "@azure/msal-react";
import * as Yup from "yup";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface IAttatchment {
  url: string;
  document_id: string;
  filename: string;
  filesize: number;
  extension: string;
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  title: string;
  submittedDate: string;
  businessUnit: string;
  ideaOwnerName: string;
  tags: string[];
  email: string;
  description: string;
  file: any;
  errors: any;
  tagList: ITagType[];
  businessUnitList: IDepartmentType[];
  attatchment: any[];
  createLoading: boolean;
  edit: boolean;
  editProjectTagList: FlatTagType[];
  editProjectInfo: any;
  addMoreTag: boolean;
  savedTag: string;
  isAdmin: boolean;
  editProjectAttachments: IAttatchment[];
  deleteDocID: string[];
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomformController extends BlockComponent<Props, S, SS> {
  static contextType = MsalContext;
  getTagListApiCallId: string = "";
  getBusinessUnitListApiCallId: string = "";
  createBusinessUnitApiCallId: string = "";
  editBusinessUnitApiCallId: string = "";
  getSingleProjectApiCallId: string = "";
  apiGetLoggedInUserCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      title: "",
      submittedDate: new Date().toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }),
      businessUnit: "",
      ideaOwnerName: "",
      email: "",
      description: "",
      file: null,
      errors: {},
      tagList: [],
      businessUnitList: [],
      tags: [],
      attatchment: [],
      createLoading: false,
      edit: false,
      editProjectTagList: [],
      editProjectInfo: {},
      addMoreTag: false,
      savedTag: "",
      isAdmin: true,
      editProjectAttachments: [],
      deleteDocID: [],
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.errors?.length > 0 && responseJson?.errors[0]?.token) {
        localStorage.removeItem("accessToken");
        this.navigateToLogin();
      }
      this.checkApiRequestPayload(apiRequestCallId, responseJson);
    }

    // Customizable Area Start
    // Customizable Area End
  }

  checkApiRequestPayload = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.apiGetLoggedInUserCallId) {
      this.handleUserNavigation(responseJson?.is_admin);
    }
    if (apiRequestCallId === this.getTagListApiCallId) {
      this.handleTagListPayload(responseJson);
    }
    if (apiRequestCallId === this.getBusinessUnitListApiCallId) {
      this.handleBusinessListPayload(responseJson);
    }
    if (apiRequestCallId === this.createBusinessUnitApiCallId) {
      this.handleCreateProjectPayload(responseJson);
    }
    if (apiRequestCallId === this.editBusinessUnitApiCallId) {
      this.handleEditProjectPayload(responseJson);
    }
    if (apiRequestCallId === this.getSingleProjectApiCallId) {
      this.handleGetEditProjectPayload(responseJson);
    }
  };
  handleUserNavigation = (isAdmin: boolean) => {
    this.setState({ isAdmin: isAdmin });
    const isAuthenticated = this.context?.accounts.length > 0;

    if (!isAuthenticated) {
      this.navigateToLogin();
      return;
    }
    const user = this.context?.accounts[0];
      this.setState({
        ideaOwnerName: user?.name,
        email: user?.username,
      });
  };

  handleTagListPayload = (responseJson: any) => {
    this.setState({
      tagList: responseJson?.data?.length > 0 ? responseJson?.data : [],
      savedTag: "-1",
    });
  };
  handleBusinessListPayload = (responseJson: any) => {
    this.setState({
      businessUnitList:
        responseJson?.data?.length > 0 ? responseJson?.data : [],
      businessUnit:
        responseJson?.data?.length > 0 ? responseJson?.data[0]?.id : "",
    });
  };
  handleEditProjectPayload = (responseJson: any) => {
    if (responseJson?.data?.id) {
      toast.success("Project Updated");
      this.navigateToHomePage();
    } else {
      console.log("Hello", responseJson);
      const message = responseJson?.message
        ? responseJson?.message
        : "Project Update Failed.";
      toast.error(message);
      if (responseJson?.errors?.length > 0) {
        responseJson.errors.map((error: string) =>
          toast.error(error?.toString())
        );
      }
    }
    this.setState({ createLoading: false });
  };
  handleCreateProjectPayload = (responseJson: any) => {
    this.setState({ createLoading: false });
    if (responseJson?.data?.id) {
      toast.success("Your idea has been successfully submitted");
      this.navigateToSubmitPage();
    } else {
      toast.error("Project submit failed.");
      if (responseJson?.errors?.length > 0) {
        responseJson.errors.map((error: string) =>
          toast.error(error?.toString())
        );
      }
    }
  };
  handleGetEditProjectPayload = (responseJson: any) => {
    if (responseJson?.data?.id) {
      const date = new Date();
      const submittedDate = `${date?.getDate()}/${date?.getMonth() +
        1}/${date?.getFullYear()}`;
      this.setState({
        title: responseJson?.data?.attributes?.idea_title,
        businessUnit: responseJson?.data?.attributes?.business_unit?.id?.toString(),
        email: responseJson?.data?.attributes?.email,
        ideaOwnerName: responseJson?.data?.attributes?.idea_owner_name,
        submittedDate: submittedDate,
        editProjectTagList: responseJson?.data?.attributes?.tags,
        description: responseJson?.data?.attributes?.idea_description,
        editProjectAttachments: responseJson?.data?.attributes?.documents,
        edit: true,
        editProjectInfo: responseJson?.data,
      });
    }
  };

  async componentDidMount() {
    super.componentDidMount();
    this.getLoggedInUser();
    this.getBusinessUnitList();
    this.getTagList();
    if (window.location.pathname.includes("/EditProject")) {
      this.getSingleProject();
    }
    // Customizable Area Start
    // Customizable Area End
  }

  getLoggedInUser = () => {
    const header = {
      token: localStorage.getItem("accessToken"),
      "Content-Type": configJSON.applicationJsonContentType,
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `account_block/accounts/admin_account`,
      method: "GET",
      body: null,
    });
    this.apiGetLoggedInUserCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  };
  getSingleProject = () => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("accessToken"),
    };
    const projectId = this.props.navigation.getParam("projectId");
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.getSingleProjectApiUrl}/${projectId}`,
      method: configJSON.getApiMethodType,
      body: null,
    });
    this.getSingleProjectApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getTagList = () => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: configJSON.tagApiUrl,
      method: configJSON.getApiMethodType,
      body: null,
    });
    this.getTagListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getBusinessUnitList = () => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: configJSON.businessUnitApiUrl,
      method: configJSON.getApiMethodType,
      body: null,
    });
    this.getBusinessUnitListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  validateInput = () => {
    const projectScheme = Yup.object({
      title: Yup.string().required("Please enter title"),
      submittedDate: Yup.string().required("Please enter submitted date"),
      businessUnit: Yup.string().required("Please select busines unit"),
      ideaOwnerName: Yup.string().required("Please enter idea owner name"),
      email: Yup.string()
        .required("Please enter email")
        .email("Invalid email address"),
      description: Yup.string().required("Please enter description"),
      tags: Yup.array().required("Please select tags"),
    });
    const {
      title,
      submittedDate,
      businessUnit,
      ideaOwnerName,
      email,
      description,
      tags,
      edit,
      editProjectTagList,
    } = this.state;

    projectScheme
      .validate(
        {
          title,
          submittedDate,
          businessUnit,
          ideaOwnerName,
          email,
          description,
          tags: edit ? editProjectTagList : tags,
        },
        { abortEarly: false }
      )
      .then((value) => {
        this.preparePostBody();
      })
      .catch((err) => {
        this.populateError(err);
      });
  };

  populateError = (err: any) => {
    const { attatchment } = this.state;
    let newError: any = {};
    const MAX_ATTATCHMENT_SIZE = 30 * 1024;
    err.inner?.forEach((item: any) => {
      newError[item.path] = item.message;
    });
    if (attatchment?.length > 0) {
      attatchment?.forEach((file: any) => {
        const fileSize = file?.size / 1024;
        if (fileSize > MAX_ATTATCHMENT_SIZE) {
          newError.attatchment = `${file?.name} file exceeds 30MB limit please compress and try again`;
        }
      });
    }
    this.setState({
      errors: newError,
    });
  };
  preparePostBody = () => {
    const {
      title,
      submittedDate,
      businessUnit,
      ideaOwnerName,
      email,
      description,
      attatchment,
      tags,
      edit,
      editProjectTagList,
      deleteDocID,
    } = this.state;
    const { issus, newError } = this.checkMoreError();
    if (issus) {
      this.setState({ errors: newError });
    } else {
      this.setState({ errors: {}, createLoading: true });
      const formData = new FormData();
      if (attatchment?.length > 0) {
        attatchment?.forEach((file: any) => {
          formData.append("documents[]", file);
        });
      }

      formData.append("idea_title", title);
      formData.append("submitted_date", submittedDate);
      formData.append("business_unit_id", businessUnit);
      formData.append("idea_owner_name", ideaOwnerName);
      formData.append("idea_description", description);
      formData.append("email", email);
      if (edit) {
        editProjectTagList?.forEach((tag, index) => {
          formData.append(
            `tag_seller_accounts_attributes[${index}][tag_id]`,
            tag.id.toString()
          );
        });
        deleteDocID?.forEach((docId) => {
          formData.append(`deleted_document_ids[]`, docId);
        });
      } else {
        tags?.forEach((tag, index) => {
          formData.append(
            `tag_seller_accounts_attributes[${index}][tag_id]`,
            tag
          );
        });
      }
      if (edit) {
        this.editProject(formData);
        return;
      }

      this.createNewProject(formData);
    }
  };
  checkMoreError = () => {
    const { attatchment } = this.state;
    let newError: any = {};
    let issus = false;
    const MAX_FILE_SIZE = 30 * 1024;
    if (attatchment?.length > 0) {
      attatchment?.forEach((currentFile: any) => {
        const fileSize = currentFile?.size / 1024;
        if (fileSize > MAX_FILE_SIZE) {
          issus = true;
          newError.attatchment = `${currentFile?.name} file exceeds 30MB limit please compress and try again`;
        }
      });
    }
    return {
      issus,
      newError,
    };
  };
  createNewProject = (body: any) => {
    const header = {
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.createProjectApiUrl}`,
      body: body,
      method: configJSON.postApiMethodType,
    });
    this.createBusinessUnitApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  editProject = (body: any) => {
    const projectId = this.props.navigation.getParam("projectId");

    const header = {
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.editProjectApiUrl}/${projectId}`,
      body: body,
      method: configJSON.patchApiMethodType,
    });
    this.editBusinessUnitApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  addMoreTag = (e: any) => {
    const tagId = e.target.value;
    const { tagList, editProjectTagList } = this.state;
    //check tag already exists
    if (!tagId) {
      return;
    }
    const prevTagsExists = editProjectTagList.filter(
      (tag) => tag?.id === Number(tagId)
    );
    if (prevTagsExists?.length > 0) {
      return;
    }
    const tag = tagList.filter((tag) => tag.id === tagId)[0];
    const newFlatTag: FlatTagType = {
      color: tag.attributes?.color as string,
      created_at: "",
      updated_at: "",
      id: Number(tagId),
      name: tag?.attributes?.name,
    };
    this.setState((prevState) => ({
      editProjectTagList: [newFlatTag, ...prevState.editProjectTagList],
      savedTag: tagId,
    }));
  };
  handleRemoveTag = (tagId: number) => {
    this.setState((prev) => {
      const newTag = prev.editProjectTagList.filter((tag) => tag.id !== tagId);
      return {
        editProjectTagList: [...newTag],
      };
    });
  };
  handleDeleteAttachment = (id: string) => {
    this.setState((prev) => ({
      editProjectAttachments: prev.editProjectAttachments?.filter(
        (item) => item.document_id !== id
      ),
      deleteDocID: [...prev.deleteDocID, id],
    }));
  };
  handleAddMoreTag = () => {
    this.setState((prev) => ({
      addMoreTag: !prev.addMoreTag,
    }));
  };
  handleTitleChange = (e: any) => {
    this.setState({
      title: e.target.value,
    });
  };
  handleSubmittedDateChange = (e: any) => {
    this.setState({
      submittedDate: e.target.value,
    });
  };
  handleBusinessUnitChange = (e: any) => {
    this.setState({
      businessUnit: e.target.value,
    });
  };
  handleIdeaOwnerNameChange = (e: any) => {
    this.setState({
      ideaOwnerName: e.target.value,
    });
  };
  handleEmailChange = (e: any) => {
    this.setState({
      email: e.target.value,
    });
  };
  handleDescriptionChange = (e: any) => {
    this.setState({
      description: e.target.value,
    });
  };
  handleTagsChange = (tagId: string, checked: boolean) => {
    this.setState((prevState) => {
      const newTagList = prevState.tagList.map((item) => {
        if (item.id === tagId) {
          return {
            ...item,
            checked: checked,
          };
        } else {
          return item;
        }
      });
      const checkedTags = newTagList
        .filter((item) => item?.checked)
        .map((item) => item.id);
      return {
        tagList: newTagList,
        tags: checkedTags,
      };
    });
  };
  handleAttatchmentChange = (files: any) => {
    const filesArray = Array.from(files);
    this.setState((prev) => ({
      attatchment: [...prev.attatchment, ...filesArray],
    }));
  };
  handleRemoveAttatchment = (id: string) => {
    this.setState((prev) => ({
      attatchment: prev?.attatchment?.filter((item: any) => item?.name !== id),
    }));
  };

  navigateToLogin = () => {
    this.props.navigation.navigate("Login");
  };
  navigateToSubmitPage = () => {
    this.props.navigation.navigate("CustomFormSubmit");
  };
  navigateToApplicantDashboard = () => {
    this.props.navigation.navigate("ApplicantLandingPage");
  };
  navigateToHomePage = () => {
    const { isAdmin } = this.state;
    this.props.navigation.navigate(
      isAdmin ? "AdminLandingPage" : "ApplicantLandingPage"
    );
  };
  goBack = () => {
    this.props.navigation.goBack();
  };

  // Customizable Area Start
  // Customizable Area End
}
