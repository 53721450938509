import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { MsalContext } from "@azure/msal-react";
import CreateRestApiMessage from "../../../components/src/util/CreateRestApiMessage.web";
import {toast} from 'react-toastify'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  searchResult: string;
  landingPageProjects: any[];
  newProjectApplications: any[];
  tagList: any[];
  checkedTagList:any[];
  tagNames:any[];
  toolTipModel:boolean;
  pagination:{
    per_page: number,
            current_page: number,
            next_page: null|number,
            prev_page: null|number,
            total_pages: number,
            total_count: number
  },
  loading:boolean;
  isAdmin:boolean;
  excelDownloadLoading:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
   // Customizable Area Start
  static contextType = MsalContext;
  getNewProjectApplicationApiCallID: string = "";
  getDataBySearchApiCallID: string = "";
  getAllProjectsApiCallID: string = "";
  getTagListApiCallID: string ="";
  getFilteredTagListApiCallId :string ="";
  apiGetLoggedInUserCallId:string="";
  apiDownloadExcellCallId:string="";
  observer:any=null;
  containerRef:any=null;
   // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ]);
    
    this.subScribedMessages = [
    ];

    this.state = {
      landingPageProjects: [],
      newProjectApplications: [],
      searchResult: "",
      tagList: [],
      checkedTagList:[],
      tagNames:[],
      toolTipModel:false,
      pagination:{
        current_page:1,
        next_page:1,
        per_page:10,
        prev_page:null,
        total_count:0,
        total_pages:0,
      },
      loading:false,
      isAdmin:localStorage?.getItem("isAdmin")==="true"?true:false,
      excelDownloadLoading:false
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);    
  }
  
  async receive(from: string, message: Message) {
    // Customizable Area Start  
     if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (response?.errors?.length > 0 && response?.errors[0]?.token) {
        localStorage.removeItem("accessToken");
        this.navigateToLogin();
      }
      this.checkAPIRequest(apiRequestId,response);      
    }
    // Customizable Area End
  }
   // Customizable Area Start

   checkAPIRequest=(apiRequestId:any,response:any)=>{
    if (apiRequestId === this.apiGetLoggedInUserCallId) {
      this.handleUserNavigation(response?.is_admin);
    }
    if (apiRequestId === this.getAllProjectsApiCallID) {
      this.handleGetAllProjectPayload(response);
    }
    if (apiRequestId === this.getNewProjectApplicationApiCallID) {        
      this.handleGetNewProjectApplicationsPayload(response);
    }
    if (apiRequestId === this.getDataBySearchApiCallID) {
      this.handleGetSearchResultPayload(response);
    }
    if (apiRequestId === this.getTagListApiCallID) {
      this.handleGetTagListPayload(response);
    }
    if (apiRequestId === this.getFilteredTagListApiCallId) {
      this.handleTagListByFilterPayload(response);
    }
    if (apiRequestId === this.apiDownloadExcellCallId) {
      this.handleExcelDownload(response);
    }
   }

   handleUserNavigation=(isAdmin:boolean)=>{
    this.setState({isAdmin:isAdmin})
    const isAuthenticated = this.context?.accounts.length > 0;
      if (!isAuthenticated) {
        this.navigateToLogin();
        return;
      }
      if (window.location.pathname.includes("/AdminLandingPage") || window.location.pathname.includes("/AdminBrowseProject")) {
        if(!isAdmin){
          this.navigateToApplicantDashboard();
        }
      }
      if (window.location.pathname.includes("/ApplicantLandingPage") ) {
        if(isAdmin){
          this.navigateToAdminDashboard();
        }
      }
  }

  navigateToApplicantDashboard = () => {
    this.props.navigation.navigate("ApplicantLandingPage");
  };

  navigateToAdminDashboard = () => {
    this.props.navigation.navigate("AdminLandingPage");
  };
  

  handleGetAllProjectPayload = (response: any) => {
    this.setState((prev)=>{
      let landingPageProjects:any[]=[];
      if(response?.data?.length>0 && prev.pagination?.next_page===1){
         landingPageProjects=response?.data
      }else if(response?.data?.length>0 && prev.pagination?.next_page && prev.pagination?.next_page>1){
         landingPageProjects=[...prev.landingPageProjects,...response?.data]
      }else if(response?.data?.length>0 && prev.pagination?.next_page===null){
        landingPageProjects=response?.data;
      }
      else if(response?.data?.length===0){
        landingPageProjects=prev.landingPageProjects
      }
      return{
        landingPageProjects:landingPageProjects,
        loading:false,
        pagination:response?.meta?.pagination,
      }
    })

  }

  handleGetNewProjectApplicationsPayload = (response: any) => {
    this.setState((prev)=>{
      let newProjects:any[]=[];
      if(response?.data?.length>0 && prev.pagination?.next_page===1){
         newProjects=response?.data
      }else if(response?.data?.length>0 && prev.pagination?.next_page && prev.pagination?.next_page>1){
         newProjects=[...prev.newProjectApplications,...response?.data]
      }else if(response?.data?.length>0 && prev.pagination?.next_page===null){
        newProjects=response?.data;
      }else if(response?.data?.length===0){
        newProjects=prev.newProjectApplications
      }

      return{
        newProjectApplications:newProjects,
        landingPageProjects:newProjects,
        loading:false,
        pagination:response?.meta?.pagination 
      }
    })

  }
  handleGetSearchResultPayload = (response: any) => {
    this.setState({ landingPageProjects: response?.data,loading:false });
    this.setState({ newProjectApplications: response?.data });
  }
  handleGetTagListPayload=(response:any)=>{
    this.setState({tagList:response?.data});
  }
  handleTagListByFilterPayload=(response:any)=>{
    this.setState({ landingPageProjects: response?.data,loading:false });
    this.setState({ newProjectApplications: response?.data });
  }
  handleExcelDownload=(response:any)=>{
      this.setState({
        excelDownloadLoading:false
      })
      const link=`https://ideaplatformws2022-290997-ruby.b290997.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbDhCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--c5c1359a8de25c35b7cff67d586cc7937db77251/IT%20Soln%20(2).pdf`
      fetch(link)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement('a');
        a.href = url;
        a.download = link.split('/').pop() as string;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }).catch(err=>{
         toast.error("Something wen't wrong")
      }).finally(()=>{
         this.setState({excelDownloadLoading:false})
      })
  }

  async componentDidMount() {
    this.getUserType();
    this.getAllProjects();
    this.getTagList();
    if (window.location.pathname.includes("/AdminLandingPage")) {
       this.getNewProjectApplications();
    }

  }
  downloadAsExcel=()=>{
    this.setState({excelDownloadLoading:true})
    const header = {
      token: localStorage.getItem("accessToken"),
      "Content-Type": configJSON.applicationJsonContentType,
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: "download-excel",
      method: "GET",
      body: null,
    });
    this.apiDownloadExcellCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }
  getUserType = () => {
    const header = {
      token: localStorage.getItem("accessToken"),
      "Content-Type": configJSON.applicationJsonContentType,
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: "account_block/accounts/admin_account",
      method: "GET",
      body: null,
    });
    this.apiGetLoggedInUserCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  };
 
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleOnSearch = (event: any) => {
    this.getDataBySearchKeyword(event);
  }

  getDataBySearchKeyword = (event: any) => {
    const value=event?.target?.value;
    if(!value){
      this.setState((prev)=>(
        {
          pagination:{
            ...prev.pagination,
            next_page:1
          },
          newProjectApplications:[],
          searchResult:""
        }
      ),()=>{
        this.getNewProjectApplications()
      })
       return;
    }
    this.setState((prev)=>(
          {
              loading:true,
              newProjectApplications:[],
              landingPageProjects:[],
              pagination:{
                ...prev.pagination,
                next_page:null
              },
              searchResult:value,
              tagList:prev?.tagList?.map((item:any)=>({...item,checked:false}))
        }),()=>{
        const header = {
          "Content-Type": configJSON.applicationJsonContentType,
          token: localStorage.getItem("accessToken"),
        };
        const requestMessage = CreateRestApiMessage({
          header,
          apiUrl: `${configJSON.getDataBySearchKeyword}?search=${value}&commit=Search`,
          body: null,
          method: configJSON.getApiMethodType,
        });
        this.getDataBySearchApiCallID = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    })
    
  };

  checkHandler=(id:string,checked:boolean)=>{
     this.setState(prev=>{
      const newSelectedTags=prev.tagList?.map(tag=>{
         if(tag?.id===id){
           return{
             ...tag,
             checked:checked
           }
         }else{
           return tag
         }
        })
        return{
          tagList:newSelectedTags,
          searchResult:""
        }
     },()=>{
       const {tagList}=this.state;
       const filteredTagId=tagList.filter((item)=>item?.checked)?.map((item)=>item?.id);
       this.handleOnCheckBoxClick(filteredTagId)
     })
  }
  handleOnCheckBoxClick=(data:string[])=>{
   
    if(data?.length>0){
      this.setState(prev=>({
          loading:true,
          pagination:{
                ...prev.pagination,
                next_page:null
           }
        }))
       const header = {
        "Content-Type": configJSON.applicationJsonContentType,
        token: localStorage.getItem("accessToken"),
      };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.getProjectByTagFilter}?tag_ids=[${data}]`,
      body: null,
      method: configJSON.getApiMethodType,
    });
  
    this.getFilteredTagListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }else{
    this.setState((prev)=>(
      {
        pagination:{
          ...prev.pagination,
          next_page:1,
        },
        landingPageProjects:[]
      }
    ),()=>{
      this.getAllProjects()
    })
  
  }
  }

  getAllProjects = () => {
    const {pagination}=this.state;
    if(pagination?.next_page===null){
      return;
    }
    this.setState({loading:true});
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.getAllProjects}?per=${pagination?.per_page}&page=${pagination?.next_page}`,
      body: null,
      method: configJSON.getApiMethodType,
    });
    this.getAllProjectsApiCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getNewProjectApplications = () => {
    
    const {pagination}=this.state;
    if(pagination?.next_page===null){
      return;
    }

    this.setState({loading:true})
    const hitUrl=`${configJSON.getNewProjectApplications}?per=${pagination?.per_page}&page=${pagination?.next_page}`

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: hitUrl,
      body: null,
      method: configJSON.getApiMethodType,
    });
    this.getNewProjectApplicationApiCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTagList = () => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: configJSON.getTagList,
      body: null,
      method: configJSON.getApiMethodType,
    });
    this.getTagListApiCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  checkedCheckBoxName=(tagNames:any[])=>{
    this.setState({tagNames:[...tagNames]})
  }
 
  navigateToLogin = () => {
    this.props.navigation.navigate("Login");
  };

  handleonClickToNewProject=()=>{
    this.props.navigation.navigate("CreateProject");
  }

  handleOnRedirectToLandingPage=()=>{
    const isAdmin =localStorage?.getItem("isAdmin")==="true"?true:false;
    if(!!isAdmin)
    {
      this.props.navigation.navigate("AdminLandingPage");
    }
    else{
      this.props.navigation.navigate("ApplicantLandingPage");
    }
  }

  showProjectDetail=(id:any)=>{
    this.props.navigation.navigate("ProjectDetails",{id});
    }
  // Customizable Area End
}
