import React from "react";
import { Box, Typography, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { imgDoc, imgFile, imgPdf } from "../../../blocks/customform/src/assets";

const getFileImage = (type: string) => {
  if (type === "doc" || type === "docx" || type === "ppt") {
    return imgDoc;
  } else if (type === "pdf") {
    return imgPdf;
  } else {
    return imgFile;
  }
};

interface Props {
  id?: string;
  fileName: string;
  url?: string;
  fileType: string;
  fileSize: number | string;
  deleteHandler?: any;
  clickable?: boolean;
  deletable?: boolean;
  radius?: number;
}

const AttatchmentItem = ({
  fileName,
  url,
  fileType,
  fileSize,
  deleteHandler,
  clickable = true,
  deletable,
  id,
  radius = 10,
}: Props) => {
  const handleClick = () => {
    if (clickable) {
      window.open(url);
    }
  };
  return (
    <Box
      style={{
        display: "flex",
        background: "white",
        padding: "10px 15px",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: `${radius}px`,
      }}
    >
      <Box
        onClick={handleClick}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          cursor: "pointer",
        }}
      >
        <img
          src={getFileImage(fileType)}
          style={{ height: "25px", width: "25px" }}
        />
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "12px",
              wordBreak: "break-all",
            }}
            component="h1"
            variant="h6"
          >
            {fileName}
          </Typography>
          <Typography style={{ fontSize: "12px" }} component="h1" variant="h6">
            {fileSize}MB
          </Typography>
        </Box>
      </Box>

      {deletable && (
        <IconButton onClick={() => deleteHandler(id)}>
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default AttatchmentItem;
